<template>
  <v-dialog
    v-model="dialogModel"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    max-width="fit-content"
  >
    <v-card>
      <v-toolbar dark color="primary lighten-2">
        <v-toolbar-title>Quantity</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="pa-3">
        <Keypad
          :initial-value="oldQuantity"
          :loading="loading"
          @submit="submit"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Keypad from "@/components/Keypad";
export default {
  name: "QuantityDialog",
  components: { Keypad },
  props: ["dialogModel", "oldQuantity", "loading"],
  methods: {
    submit(newVal) {
      this.$emit("submit", newVal);
    },
  },
};
</script>

<style scoped></style>
