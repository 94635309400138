export default {
  /*
  items: [],
  selectedItem: null,
  loadingItems: false,
  loadingDeleteId: null,
  error: "",
   */
  modifyQtyLoading: false,
};
