import { noomproxyBaseUrl } from "@/api/base/urls";

const inventoryChangeBaseUrl = "/customer";

const customerUrl = {
  search: `${noomproxyBaseUrl}${inventoryChangeBaseUrl}/search`,
  getOne: ({ id }) => `${noomproxyBaseUrl}${inventoryChangeBaseUrl}/${id}`,
};

export { customerUrl };
