<template>
  <div class="pa-3 w-100">
    <div class="text-h1">Product details page coming soon...</div>
  </div>
</template>

<script>
export default {
  name: "products",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
