const genericState = () => {
  return {
    items: [],
    selectedItem: null,
    loadingItems: false,
    loadingDeleteId: null,
    error: "",
  };
};

export default genericState;
