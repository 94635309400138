<template>
  <v-dialog
    v-model="dialogModel"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
    class="z-index-max"
  >
    <v-card>
      <v-toolbar dark color="primary lighten-2">
        <v-toolbar-title>Search products</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <SearchProducts
        select-icon="mdi-arrow-right"
        :headers="tableHeaders"
        @selectProduct="selectProduct"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import SearchProducts from "@/components/SearchProducts";
export default {
  name: "SearchProductsDialog",
  components: { SearchProducts },
  props: ["dialogModel", "tableHeaders"],
  methods: {
    selectProduct(product) {
      this.$emit("selectProduct", product);
    },
  },
};
</script>

<style scoped></style>
