<template>
  <div class="d-flex flex-column w-100">
    <v-text-field
      hide-details
      autofocus
      full-width
      class="mb-1 search-input"
      v-model="searchModel"
      :label="label"
      prepend-inner-icon="mdi-magnify"
      :append-icon="permissionToSendSearch ? 'mdi-send' : ''"
      @click:append="search"
      v-on:keydown.enter="search"
      solo
      flat
      background-color="rgba(0,0,0,.06)"
      :loading="loadingItems"
      :disabled="loadingItems"
    ></v-text-field>
    <div
      class="text-h6 align-center d-flex flex-row gap-5"
      v-if="lastSearchString && !loadingItems"
    >
      <v-icon>{{ icon }}</v-icon>
      {{ `Search results for: ${lastSearchString}` }}
    </div>
    <div class="text-h6 faded" v-if="loadingItems">Loading items...</div>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: [
    "inputModel",
    "label",
    "permissionToSendSearch",
    "loadingItems",
    "lastSearchString",
    "icon",
  ],
  computed: {
    searchModel: {
      get: function getInputVal() {
        return this.inputModel;
      },
      set: function setInputVal(newVal) {
        this.$emit("update:inputModel", newVal);
      },
    },
  },
  methods: {
    search() {
      this.$emit("search");
    },
  },
};
</script>

<style scoped>
.search-input >>> input {
  font-size: x-large;
}
.search-input >>> label {
  font-size: x-large;
}
.search-input >>> button {
  font-size: xx-large;
}
</style>
