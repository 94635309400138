export default {
  setShelfError({ commit }, payload) {
    commit("SET_ERROR_SHELF", payload);
  },
  setShelfItemsLoading({ commit }, payload) {
    commit("SET_ITEMS_LOADING_SHELF", payload);
  },
  setShelfItems({ commit }, payload) {
    commit("SET_ITEMS_SHELF", payload);
  },
  appendShelfItems({ commit }, payload) {
    commit("APPEND_ITEMS_SHELF", payload);
  },
  selectShelfItem({ commit }, payload) {
    commit("SET_SELECTED_ITEM_SHELF", payload);
  },
  setShelfDeleteLoadingId({ commit }, payload) {
    commit("SET_DELETE_LOADING_ID_SHELF", payload);
  },
};
