<template>
  <ErrorDialog
    :text="error === null ? 'N/A' : error.text"
    :is-open="error"
    @close="clearLastModuleError"
    @ok="clearLastModuleError"
    :dialog-model="dialogModel"
  />
</template>

<script>
import ErrorDialog from "@/components/ErrorDialog";
export default {
  name: "GlobalErrorPopup",
  components: { ErrorDialog },
  data() {
    return {
      dialogModel: false,
    };
  },
  computed: {
    storeModules() {
      return this.$store._modules.root._children;
    },
    error() {
      return this.getLastModuleError();
    },
  },
  methods: {
    getLastModuleError() {
      let lastModuleError = null;
      console.log("this.storeModules", this.storeModules);
      Object.keys(this.storeModules).forEach((moduleName) => {
        if (this.$store.state[moduleName]?.error) {
          console.log("this module has error:", moduleName);
          lastModuleError = {
            module: moduleName,
            text: this.$store.state[moduleName].error,
          };
        }
      });
      this.dialogModel = lastModuleError !== null;
      return lastModuleError;
    },
    clearLastModuleError() {
      // this.$store.dispatch("setLogError", "");
      console.log("clear error from module:", this.error.module);
      this.$store.state[this.error.module].error = null;
    },
  },
};
</script>

<style scoped></style>
