export default {
  SET_ITEMS_SHELF(state, payload) {
    state.items = payload;
  },
  APPEND_ITEMS_SHELF(state, payload) {
    state.items = [...state.items, ...payload];
  },
  SET_ITEMS_LOADING_SHELF(state, payload) {
    state.loadingItems = payload;
  },
  SET_ERROR_SHELF(state, payload) {
    state.error = payload;
  },
  SET_SELECTED_ITEM_SHELF(state, payload) {
    state.selectedItem = payload;
  },
  SET_DELETE_LOADING_ID_SHELF(state, payload) {
    state.loadingDeleteId = payload;
  },
};
