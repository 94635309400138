import { Api } from "@/api/base";
import { klemmergUrl } from "@/api/klemmerg/urls";

const klemmergApi = {
  createJob: async (body) => {
    return Api.post(klemmergUrl.createJob, body);
  },
  getPrinters: async () => {
    return Api.get(klemmergUrl.getPrinters);
  },
  getLastUserJob: async (username) => {
    return Api.post(klemmergUrl.getLastUserJob, { authorName: username });
  },
};

export { klemmergApi };
