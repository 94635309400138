<template>
  <div class="h-100 w-100 d-flex">
    <v-data-table
      class="h-100 w-100 d-flex flex-column overflow-hidden data-table"
      v-if="!$vuetify.breakpoint.xsOnly"
      item-class="table-row"
      fixed-header
      disable-filtering
      height="100%"
      :headers="headers"
      :items="items"
      :item-key="itemKey"
      :items-per-page="-1"
      :class="{ faded: loadingItems }"
      hide-default-footer
      :group-by="groupBy"
    >
      <template v-slot:group.header="{ group }">
        <th colspan="100" v-if="groupBy">
          <div class="w-100 text-h6">
            {{
              groupDisplays.length > 0
                ? findFromGroupDisplays(group).text
                : group
            }}
          </div>
        </th>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="selectItem(item)">
          <v-icon>{{ selectIcon }}</v-icon>
        </v-btn>
      </template>
      <template
        v-slot:[`item.${priceValue}`]="{ item }"
        v-for="priceValue in priceValues"
      >
        {{ toPriceNumber(item[priceValue]) }}
      </template>
      <template
        v-slot:[`item.${highlightValue}`]="{ item }"
        v-for="highlightValue in highlightValues"
      >
        <div
          :key="highlightValue"
          v-html="
            highlighter.highlightOrNot(item[highlightValue], lastSearchString)
          "
        ></div>
      </template>
      <template v-slot:body.append="{}">
        <tr class="hover-none" v-if="lastSearchString !== ''">
          <th colspan="100">
            <div class="w-100 mt-4 mb-4 text-center">
              <v-btn
                v-if="loadMoreEnabled"
                block
                depressed
                large
                :loading="loadingItems"
                @click="loadMore()"
              >
                Load more results
              </v-btn>
              <div
                class="text-subtitle-1 faded text-center"
                v-if="!loadMoreEnabled"
              >
                End of results
              </div>
            </div>
          </th>
        </tr>
      </template>
    </v-data-table>
    <div
      class="d-flex flex-column h-100 overflow-auto"
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-data-iterator
        :items="items"
        :item-key="itemKey"
        :items-per-page="-1"
        :single-expand="true"
        hide-default-footer
        :disable-filtering="true"
        :disable-pagination="true"
        :disable-sort="true"
        :group-by="groupBy"
        :class="{ faded: loadingItems }"
      >
        <template v-slot:default="{ groupedItems, items }">
          <div v-if="!groupBy">
            <v-expansion-panels accordion>
              <DataTableExpansionPanelMobile
                v-for="(item, i) in items"
                :key="i"
                :item="item"
                :highlight-string="lastSearchString"
                :display-item-values="displayItemValues"
                :highlight-values="highlightValues"
                :headers="headers"
                :select-icon="selectIcon"
                @selectItem="selectItem"
              />
            </v-expansion-panels>
          </div>
          <div v-if="groupBy">
            <div v-for="(groupedItem, i) in groupedItems" :key="i">
              <div class="mt-4 mb-4 text-h4 pl-3 pr-3">
                {{
                  groupDisplays.length > 0
                    ? findFromGroupDisplays(groupedItem.name).text
                    : groupedItem.name
                }}
              </div>
              <v-expansion-panels accordion>
                <DataTableExpansionPanelMobile
                  v-for="(item, i) in groupedItem.items"
                  :key="i"
                  :item="item"
                  :highlight-string="lastSearchString"
                  :display-item-values="displayItemValues"
                  :highlight-values="highlightValues"
                  :headers="headers"
                  :select-icon="selectIcon"
                  @selectItem="selectItem"
                />
              </v-expansion-panels>
            </div>
          </div>
        </template>
      </v-data-iterator>
      <div class="w-100 mt-3 pb-4 text-center" v-if="lastSearchString !== ''">
        <v-btn
          v-if="loadMoreEnabled"
          block
          depressed
          large
          :loading="loadingItems"
          @click="loadMore()"
        >
          Load more results
        </v-btn>
        <div class="text-subtitle-1 faded text-center" v-if="!loadMoreEnabled">
          End of results
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import highlighter from "@/helper/highlighter";
import { toPriceNumber } from "@/helper/number";
import DataTableExpansionPanelMobile from "@/components/DataTableExpansionPanelMobile";
export default {
  name: "DataTable",
  components: { DataTableExpansionPanelMobile },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    priceValues: {
      type: Array,
      default: function () {
        return [];
      },
    },
    highlightValues: {
      type: Array,
      default: function () {
        return [];
      },
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    lastSearchString: {
      type: String,
    },
    moreItemsToLoad: {
      type: Boolean,
    },
    loadingItems: {
      type: Boolean,
    },
    selectIcon: {
      type: String,
    },
    queryLimit: {
      type: Number,
    },
    groupBy: {
      type: [String, Array],
      default: "",
    },
    groupDisplays: {
      type: Array,
      default: function () {
        return [];
      },
    },
    displayItemValues: {
      type: Array,
    },
    itemKey: {
      type: String,
      default: "id",
    },
  },
  data() {
    return {
      highlighter: highlighter,
      toPriceNumber: toPriceNumber,
    };
  },
  computed: {
    loadMoreEnabled() {
      return this.moreItemsToLoad && this.items.length >= this.queryLimit;
    },
  },
  mounted() {
    console.log("mounted inner");
  },
  methods: {
    findFromGroupDisplays(value) {
      return this.groupDisplays.find(
        (groupDisplay) => groupDisplay.value === value
      );
    },
    selectItem(item) {
      console.log("emit inner");
      this.$emit("selectItem", item);
    },
    loadMore() {
      this.$emit("loadMore");
    },
  },
};
</script>

<style scoped></style>
