<template>
  <div class="pa-5 w-100">
    <CustomerDetails :id="$route.params.id" />
  </div>
</template>

<script>
import CustomerDetails from "@/components/CustomerDetails";
export default {
  name: "customer",
  components: { CustomerDetails },
};
</script>

<style scoped></style>
