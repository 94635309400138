import actions from "@/store/auth/actions";

export default {
  state: {
    token: null,
    account: null,
    accountRole: null,
    accounts: [],
    accountLoading: false,
    loginLoading: false,
    error: null,
    accountError: null,
    navigatePath: null,
    loginAttempts: 0,
    accountPermissions: [],
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_LOGIN_LOADING(state, payload) {
      state.loginLoading = payload;
    },
    SET_AUTH_ERROR(state, payload) {
      state.error = payload;
    },
    SET_NAVIGATE_PATH(state, payload) {
      state.navigatePath = payload;
    },
    SET_ACCOUNT(state, payload) {
      state.account = payload;
    },
    SET_ACCOUNT_ROLE(state, payload) {
      state.accountRole = payload;
    },
    SET_ACCOUNT_PERMISSIONS(state, payload) {
      state.accountPermissions = payload;
    },
    SET_ACCOUNT_ERROR(state, payload) {
      state.error = payload;
    },
    SET_ACCOUNT_LOADING(state, payload) {
      state.accountLoading = payload;
    },
    SET_ACCOUNT_ITEMS(state, payload) {
      state.accounts = payload;
    },
    INCREASE_LOGIN_ATTEMPT(state) {
      state.loginAttempts += 1;
    },
    RESET_LOGIN_ATTEMPT(state) {
      state.loginAttempts = 0;
    },
  },
  actions,
};
