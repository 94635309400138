<template>
  <v-app id="app">
    <v-main class="h-100">
      <GlobalErrorPopup />
      <Toolbar v-if="$route.meta.hideToolbar === undefined" />
      <div
        class="h-100 d-flex"
        :class="{
          'padding-under-toolbar': $route.meta.hideToolbar === undefined,
        }"
      >
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<style>
#app,
html,
body {
  height: 100%;
}
</style>
<script>
import Toolbar from "@/components/Toolbar";
import GlobalErrorPopup from "@/components/GlobalErrorPopup";
export default {
  components: { GlobalErrorPopup, Toolbar },
};
</script>

<style>
.hover-none:hover {
  background-color: transparent !important;
}
.highlight {
  background-color: #ffff0091;
}
.z-index-max {
  z-index: 10 !important;
}
.bg-light {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
html {
  overflow: auto !important;
}
.padding-under-toolbar {
  padding-top: 64px;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-20 {
  gap: 20px;
}
.faded {
  opacity: 0.4;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
