import { authApi } from "@/api/auth";
import store from "@/store";

const authService = {
  checkToken: async () => {
    const token = store.state.auth.token;
    try {
      const response = await authApi.check({ token });
      return !!response.ok;
    } catch (e) {
      console.error("ERROR in authService checkToken", e);
      return false;
    }
  },
  setAccount: async (account, jwtToken) => {
    const accountRole =
      authService.getAccountRoleFromAccountApiResponse(account);
    console.log("account", account);
    console.log("accountRole", account.accountRole);
    if (accountRole === null) {
      // throw new Error("Account does not have a role!");
    }
    await store.dispatch("setAccountRole", account.accountRole);
    await store.dispatch("setToken", jwtToken);
    await store.dispatch("setAccount", account);
    await store.dispatch(
      "setAccountPermissions",
      authService.getPermissionListFromAccountApiResponse(account)
    );
  },
  increaseLoginAttempt: async () => {
    await store.dispatch("increaseLoginAttempt");
  },
  resetLoginAttempt: async () => {
    await store.dispatch("resetLoginAttempt");
  },
  logout: async () => {
    await store.dispatch("setToken", null);
    await store.dispatch("setAccount", null);
  },
  getTerminalAccounts: async (body) => {
    await store.dispatch("setAccountLoading", true);
    try {
      const response = await authApi.getTerminalAccountsByOwnerId(body);
      if (response.ok) {
        const responseObj = await response.json();
        await store.dispatch("setAccountItems", responseObj);
        await store.dispatch("setAccountLoading", false);
        await store.dispatch("setAccountError", null);
        return true;
      } else {
        const responseText = await response.text();
        const errorMessage = "ERROR in authService getAccounts" + responseText;
        await store.dispatch("setAccountError", responseText);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in authService getAccounts" + e;
      await store.dispatch("setAccountError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setAccountLoading", false);
    return false;
  },
  getAccountRoleFromAccountApiResponse: (account) => {
    if (
      account.accountServiceAccountRoles !== undefined &&
      account.accountServiceAccountRoles.length > 0
    ) {
      const accountServiceAccountRole = account.accountServiceAccountRoles.find(
        (accountServiceAccountRole) =>
          accountServiceAccountRole.service.id ===
          process.env.VUE_APP_SERVICE_ID
      );
      return accountServiceAccountRole.accountRole !== undefined
        ? accountServiceAccountRole.accountRole
        : null;
    }
    return null;
  },
  getPermissionListFromAccountApiResponse: (account) => {
    const accountRole =
      authService.getAccountRoleFromAccountApiResponse(account);
    return accountRole === null
      ? null
      : accountRole.accountRolePermissions.map(
          (accountRolePermission) => accountRolePermission.permission.id
        );
  },
  login: async (body) => {
    await store.dispatch("setLoginLoading", true);
    try {
      const response = await authApi.checkUserCredentials(body);
      if (response.ok) {
        const responseObj = await response.json();
        if (
          responseObj.account !== undefined &&
          responseObj.jwttoken !== undefined
        ) {
          await authService.setAccount(
            responseObj.account,
            responseObj.jwttoken
          );
        } else {
          throw new Error("No account or token from API response!");
        }
        await store.dispatch("setLoginLoading", false);
        await store.dispatch("setAuthError", null);
        return true;
      } else {
        const responseText = await response.text();
        const errorMessage = "ERROR in authService login" + responseText;
        await store.dispatch("setAuthError", responseText);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in authService login" + e;
      await store.dispatch("setAuthError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setLoginLoading", false);
    return false;
  },
};

export default authService;
