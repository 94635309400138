import store from "@/store";

const authHeaders = (needAuth) => {
  return store.state.auth.token && needAuth
    ? {
        Authorization: `Bearer ${store.state.auth.token}`,
        service: process.env.VUE_APP_SERVICE_ID,
      }
    : {};
};
const responseAuthCheck = async (response, auth) => {
  if (auth && (response.status === 403 || response.status === 401)) {
    await window.vueRouterHook({ path: "/pass" }).catch(() => {});
  }
};

const Api = {
  get: async (url, auth = true) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        ...authHeaders(auth),
      },
    });
    await responseAuthCheck(response, auth);
    return response;
  },
  post: async (url, body, auth = true) => {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...authHeaders(auth),
      },
    });
    await responseAuthCheck(response, auth);
    return response;
  },
  put: async (url, body, auth = true) => {
    const response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...authHeaders(auth),
      },
    });
    await responseAuthCheck(response, auth);
    return response;
  },
  delete: async (url, auth = true) => {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        ...authHeaders(auth),
      },
    });
    await responseAuthCheck(response, auth);
    return response;
  },
};

export { Api };
