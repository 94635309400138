<template>
  <div class="user-grid-wrapper d-flex flex-wrap gap-20 justify-center">
    <v-card
      v-for="account in accounts"
      :key="account.id"
      class="user-card"
      @click="setAccount(account)"
    >
      <v-card-title>{{ account.name }}</v-card-title>
    </v-card>
  </div>
</template>

<script>
import authService from "@/service/authService";

export default {
  name: "UserGrid",
  computed: {
    accounts() {
      return this.$store.state.auth.accounts;
    },
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    async setAccount(account) {
      try {
        await authService.setAccount(account);
        //this.$store.commit('START_LOGOUT_TIMER');
        //this.startUserLogoutTimer();
        await this.$router.push({ path: this.navigatePath || "/" });
      } catch (e) {
        alert(e);
      }
    },
    async getAccounts() {
      await authService.getTerminalAccounts(
        this.$store.state.auth.account?.owner.id
      );
    },
  },
};
</script>

<style scoped>
.user-card {
  cursor: pointer;
}
</style>
