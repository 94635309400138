import store from "@/store/index";
import { productBarcodeApi } from "@/api/productBarcode";

const productBarcodeService = {
  fetchByProductId: async (id) => {
    try {
      const response = await productBarcodeApi.getByProductId(id);
      if (response.ok) {
        return await response.json();
      } else {
        await store.dispatch("setShelfError", "ERROR in productBarcodeService");
        console.error("ERROR in productBarcodeService", response);
      }
    } catch (e) {
      await store.dispatch(
        "setShelfError",
        "ERROR in productBarcodeService: " + e
      );
      console.error("ERROR in productBarcodeService", e);
    }
  },
};

export default productBarcodeService;
