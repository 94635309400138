import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import Home from "./views/Home";
import SelectUser from "./views/SelectUser";
import Products from "./views/Products";
import Product from "./views/Product";
import Customers from "./views/Customers";
import Customer from "./views/Customer";
import Login from "./views/Login";
import Inventory from "./views/Inventory";
import Location from "./views/Location";
import ChooseLocation from "@/views/ChooseLocation";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  routes: [
    {
      path: "/pass",
      name: "login",
      component: Login,
      meta: { hideToolbar: true, noToken: true },
    },
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/select-user",
      name: "select-user",
      component: SelectUser,
      meta: { hideToolbar: true },
    },
    {
      path: "/product",
      name: "products",
      component: Products,
    },
    {
      path: "/product/:id",
      name: "product",
      component: Product,
    },
    {
      path: "/customer",
      name: "customers",
      component: Customers,
    },
    {
      path: "/customer/:id",
      name: "customer",
      component: Customer,
    },
    {
      path: "/inventory/:tab",
      name: "inventory",
      component: Inventory,
    },
    {
      path: "/location",
      name: "chooseLocation",
      component: ChooseLocation,
    },
    {
      path: "/location/:id",
      name: "location",
      component: Location,
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log("routed to ", to.path);
  if (to.name !== "login") {
    // store.dispatch("setServiceId", to.meta?.service?.id || null);
    store.dispatch("setNavigatePath", to.path);
  }
  if (store.state.auth.token === null && !to.meta.noToken) {
    next({
      path: `/pass`,
    });
  } else if (
    store.state.auth.accountRole?.id ===
      process.env.VUE_APP_TERMINAL_ACCOUNT_ROLE_ID &&
    to.path !== "/select-user"
  ) {
    next({
      path: `/select-user`,
    });
  } else {
    next();
  }
});

export default router;
