<template>
  <div class="w-100" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-3'">
    <SearchCustomers
      select-icon="mdi-text"
      @selectCustomer="selectCustomer"
      :headers="$vuetify.breakpoint.xsOnly ? tableHeadersMobile : tableHeaders"
    />
  </div>
</template>

<script>
import SearchCustomers from "@/components/SearchCustomers";
import {
  generalAllInfoCustomerHeaders,
  generalAllInfoCustomerHeadersMobile,
} from "@/constants/dataTableHeadersCustomer";
export default {
  name: "customers",
  components: { SearchCustomers },
  data() {
    return {
      tableHeaders: generalAllInfoCustomerHeaders,
      tableHeadersMobile: generalAllInfoCustomerHeadersMobile,
    };
  },
  methods: {
    selectCustomer(customer) {
      this.$router.push({ path: `/customer/${customer.id}` });
    },
  },
};
</script>

<style scoped></style>
