<template>
  <div class="d-flex flex-column w-100 h-100">
    <v-btn x-large @click="getInventoryChanges">
      <v-icon>mdi-refresh</v-icon>
      Refresh
    </v-btn>
    <v-data-table
      class="h-100 d-flex flex-column overflow-hidden data-table"
      item-class="table-row"
      fixed-header
      :dense="$vuetify.breakpoint.xsOnly"
      height="100%"
      :headers="headers"
      :items="inventoryChanges"
      :items-per-page="itemsPerPage"
      :class="{ faded: loadingItems }"
      :loading="loadingItems"
      :disable-sort="$vuetify.breakpoint.xsOnly"
      :hide-default-footer="inventoryChanges.length === 0"
      :footer-props="{
        itemsPerPageOptions: [itemsPerPage],
        options: {
          itemsPerPage: itemsPerPage,
          disableItemsPerPage: true,
          itemsPerPageText: '',
        },
      }"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="red lighten-2"
          icon
          @click="deleteInventoryChange(item.id)"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.timeCreated="{ item }">
        {{ formatTime(item.timeCreated) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { generalInventoryChangeHeaders } from "@/constants/dataTableHeadersInventoryChange";
import inventoryChangeService from "@/service/inventoryChangeService";
import timeHelper from "@/helper/time";
export default {
  name: "InventoryChangesContent",
  data() {
    return {
      itemsPerPage: -1,
      minSearchCharCount: 3,
      searchFilter: "",
      headers: generalInventoryChangeHeaders,
    };
  },
  mounted() {
    this.getInventoryChanges();
  },
  computed: {
    inventoryChanges() {
      return this.$store.state.inventoryChange.items;
    },
    loadingItems() {
      return this.$store.state.inventoryChange.loadingItems;
    },
  },
  methods: {
    async getInventoryChanges() {
      await inventoryChangeService.fetchInventoryChangeItems();
    },
    async deleteInventoryChange(id) {
      await inventoryChangeService.deleteById(id);
      await this.getInventoryChanges();
    },
    formatTime(dateTime) {
      return timeHelper.formatDateTime(dateTime);
    },
  },
};
</script>

<style scoped></style>
