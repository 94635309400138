export default {
  setProductError({ commit }, payload) {
    commit("SET_ERROR_PRODUCT", payload);
  },
  setProductItemsLoading({ commit }, payload) {
    commit("SET_ITEMS_LOADING_PRODUCT", payload);
  },
  setProductItems({ commit }, payload) {
    commit("SET_ITEMS_PRODUCT", payload);
  },
  appendProductItems({ commit }, payload) {
    commit("APPEND_ITEMS_PRODUCT", payload);
  },
  selectProductItem({ commit }, payload) {
    commit("SET_SELECTED_ITEM_PRODUCT", payload);
  },
  setProductDeleteLoadingId({ commit }, payload) {
    commit("SET_DELETE_LOADING_ID_PRODUCT", payload);
  },
  setProductLastSearchString({ commit }, payload) {
    commit("SET_LAST_SEARCH_STRING_PRODUCT", payload);
  },
  setProductLastScanString({ commit }, payload) {
    commit("SET_LAST_SCAN_STRING_PRODUCT", payload);
  },
  setProductModifyLoadingId({ commit }, payload) {
    commit("SET_MODIFY_LOADING_ID_PRODUCT", payload);
  },
};
