export default {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_ITEMS_LOADING(state, payload) {
    state.loadingItems = payload;
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_SELECTED_ITEM(state, payload) {
    state.selectedItem = payload;
  },
  SET_DELETE_LOADING_ID(state, payload) {
    state.loadingDeleteId = payload;
  },
  SET_MODIFY_QTY_LOADING(state, payload) {
    state.modifyQtyLoading = payload;
  },
};
