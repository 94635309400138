var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"align-start"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"faded caption",domProps:{"innerHTML":_vm._s(
          _vm.highlighter.highlightOrNot(
            _vm.item[_vm.displayItemValues[0]],
            _vm.highlightString
          )
        )}}),_c('div',{staticClass:"text-break text-h6",domProps:{"innerHTML":_vm._s(
          _vm.highlighter.highlightOrNot(
            _vm.item[_vm.displayItemValues[1]],
            _vm.highlightString
          )
        )}}),(_vm.item[_vm.displayItemValues[2]])?_c('div',{staticClass:"faded caption",domProps:{"innerHTML":_vm._s(
          _vm.highlighter.highlightOrNot(
            _vm.item[_vm.displayItemValues[2]],
            _vm.highlightString
          )
        )}}):_vm._e()])]),_c('v-expansion-panel-content',{staticClass:"pa-0 grey lighten-3 innerExPan"},[_c('v-divider'),_c('v-list',{staticClass:"pa-0 grey lighten-3",attrs:{"dense":""}},_vm._l((_vm.headers),function(header){return _c('v-list-item',{key:header.value,staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"text-break"},[_c('div',{staticClass:"faded caption"},[_vm._v(_vm._s(header.text))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.item[header.value]))]),(header.value === 'actions')?_c('v-btn',{staticClass:"justify-start",attrs:{"icon":""},on:{"click":function($event){return _vm.selectItem(_vm.item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.selectIcon))])],1):_vm._e()],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }