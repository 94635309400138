var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column w-100 h-100"},[_c('SearchInput',{attrs:{"input-model":_vm.searchFilter,"icon":"mdi-account","permission-to-send-search":_vm.permissionToSendSearch,"last-search-string":_vm.lastSearchString,"loading-items":_vm.loadingItems,"label":"Search customers"},on:{"search":function($event){return _vm.search(0)},"update:inputModel":_vm.updateInput}}),_c('div',{staticClass:"h-100 w-100 d-flex flex-column overflow-hidden data-table"},[_c('DataTable',{attrs:{"items":_vm.tableItems,"headers":_vm.headers,"highlight-values":[
        'searchCode',
        'name',
        'address',
        'foundByCustomerContactName' ],"display-item-values":[
        'searchCode',
        'name',
        'foundByCustomerContactName' ],"last-search-string":_vm.lastSearchString,"loading-items":_vm.loadingItems,"query-limit":_vm.itemsLoadLimit,"more-items-to-load":_vm.moreItemsToLoad,"select-icon":_vm.selectIcon,"group-by":"byCustomerContact","group-displays":_vm.groupDisplays,"item-key":"index"},on:{"loadMore":function($event){return _vm.loadMore()},"selectItem":_vm.selectItem}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }