<template>
  <v-container fluid>
    <div
      class="
        pa-lg-8 pa-sm-2
        login-wrapper
        justify-center
        align-center
        w-100
        h-100
        d-flex
      "
    >
      <SelectUser />
    </div>
  </v-container>
</template>

<script>
import SelectUser from "@/components/SelectUser";
export default {
  name: "select-user",
  components: { SelectUser },
};
</script>

<style scoped></style>
