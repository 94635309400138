<template>
  <div class="w-100" :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-3'">
    <SearchProducts
      v-if="permissionToSeePage"
      select-icon="mdi-sticker-minus"
      @selectProduct="selectProduct"
      :headers="$vuetify.breakpoint.xsOnly ? tableHeadersMobile : tableHeaders"
    />
    <div v-if="!permissionToSeePage">No permission to see this!</div>
    <v-dialog v-model="dialog_klemmerg" width="400">
      <v-card prepend-icon="mdi-sticker-minus" title="KLEMMERG">
        <v-card-title>
          <v-icon>mdi-sticker-minus</v-icon>
          <span>KLEMMERG</span>
        </v-card-title>
        <v-card-text>
          <v-row v-if="selectedProduct">
            <v-col
              cols="12"
              sm="12"
              class="d-flex flex-column ga-3"
              style="gap: 10px"
            >
              <span v-if="!isKlemmergDataLoading">
                {{ klemmergProductCodeField }}
              </span>
              <span v-if="!isKlemmergDataLoading">
                {{ selectedProduct.name }}
              </span>
              <span v-if="!isKlemmergDataLoading">
                {{
                  `QR: toote ${klemmergDataMatrixField.source}: ${klemmergDataMatrixField.dataMatrix}`
                }}
              </span>
              <v-select
                v-model="selectedPrinter"
                :items="printers"
                item-value="id"
                item-text="description"
                label="Vali printer"
                :loading="isKlemmergDataLoading"
                :disabled="isKlemmergDataLoading"
                return-object
                single-line
                solo
                flat
                background-color="rgba(0,0,0,.06)"
              ></v-select>
              <v-btn
                color="primary"
                :loading="isKlemmergPrintLoading || isKlemmergDataLoading"
                :disabled="
                  isKlemmergPrintLoading ||
                  isKlemmergDataLoading ||
                  !selectedPrinter
                "
                @click="klemmergPrint"
              >
                Prindi
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  generalAllInfoProductHeaders,
  generalAllInfoProductHeadersMobile,
} from "@/constants/dataTableHeadersProduct";
import SearchProducts from "@/components/SearchProducts";
import klemmergService from "@/service/klemmergService";
export default {
  name: "products",
  components: { SearchProducts },
  data() {
    return {
      tableHeaders: generalAllInfoProductHeaders,
      tableHeadersMobile: generalAllInfoProductHeadersMobile,
      printers: [],
      selectedPrinter: null,
      dialog_klemmerg: false,
      selectedProduct: null,
      isKlemmergDataLoading: false,
      isKlemmergPrintLoading: false,
      klemmergProductCodeField: "",
      klemmergDataMatrixField: "",
    };
  },
  computed: {
    permissionToSeePage() {
      /*
      return this.$store.state.auth.accountPermissions.includes(
        process.env.PERMISSION_ID_PAGE_PRODUCTS
      );
       */
      return true;
    },
  },
  methods: {
    async selectProduct(product) {
      if (product) {
        this.selectedProduct = product;
        // klemmergService.sendToQueue(product);
        // this.$router.push({ path: `/product/${product.id}` });
        this.isKlemmergDataLoading = true;
        this.dialog_klemmerg = true;
        await this.getPrinters();
        await this.getLastUserJob();
        this.klemmergProductCodeField =
          klemmergService.getProductCodeField(product);
        this.klemmergDataMatrixField = await klemmergService.getDataMatrixField(
          product
        );
        this.isKlemmergDataLoading = false;
      }
    },
    async klemmergPrint() {
      this.isKlemmergPrintLoading = true;
      await klemmergService.sendToQueue(
        this.selectedProduct,
        this.selectedPrinter,
        this.klemmergDataMatrixField.dataMatrix
      );
      this.dialog_klemmerg = false;
      this.isKlemmergPrintLoading = false;
    },
    async getPrinters() {
      const response = await klemmergService.getPrinters();
      this.printers = response
        .filter((printer) => printer.isActive === true)
        .filter((printer) => printer.label === "pt12");
    },
    async getLastUserJob() {
      const response = await klemmergService.getLastUserJob();
      if (response?.printerId) {
        this.selectedPrinter =
          this.printers.find((printer) => printer.id === response.printerId) ||
          null;
      }
    },
  },
};
</script>

<style scoped></style>
