const Helper = {
  removeNullValuesFromObject: (obj) => {
    Object.keys(obj).forEach(
      (k) => obj[k] == null && obj[k] !== undefined && delete obj[k]
    );
    return obj;
  },
};

export { Helper };
