import { noominuhkBaseUrl } from "@/api/base/urls";

const inventoryChangeBaseUrl = "/inventory-change";

const inventoryChangeUrl = {
  deleteById: (id) => `${noominuhkBaseUrl}${inventoryChangeBaseUrl}/${id}`,
  modifyItem: `${noominuhkBaseUrl}${inventoryChangeBaseUrl}`,
  getActiveInventoryChanges: `${noominuhkBaseUrl}${inventoryChangeBaseUrl}/active`,
  getActiveInventoryChangeForProduct: (productId) =>
    `${noominuhkBaseUrl}${inventoryChangeBaseUrl}/active_by_product/${productId}`,
};

export { inventoryChangeUrl };
