import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/auth";
import log from "@/store/log";
import socket from "@/store/socket";
import item from "@/store/item";
import product from "@/store/product";
import customer from "@/store/customer";
import inventoryChange from "@/store/inventoryChange";
import shelf from "@/store/shelf";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  modules: {
    auth,
    log,
    socket,
    item,
    product,
    customer,
    inventoryChange,
    shelf,
  },
  state: {},
  mutations: {},
  actions: {},
});
