<template>
  <v-dialog v-model="dialogModel" persistent>
    <v-card>
      <v-card-title class="text-h5 red lighten-2">ERROR</v-card-title>
      <v-card-text class="mt-5">
        {{ text }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="align-center justify-center d-flex">
        <v-btn x-large color="primary" text @click="$emit('ok')">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  props: ["dialogModel", "text"],
};
</script>

<style scoped></style>
