var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 w-100 d-flex"},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-data-table',{staticClass:"h-100 w-100 d-flex flex-column overflow-hidden data-table",class:{ faded: _vm.loadingItems },attrs:{"item-class":"table-row","fixed-header":"","disable-filtering":"","height":"100%","headers":_vm.headers,"items":_vm.items,"item-key":_vm.itemKey,"items-per-page":-1,"hide-default-footer":"","group-by":_vm.groupBy},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
return [(_vm.groupBy)?_c('th',{attrs:{"colspan":"100"}},[_c('div',{staticClass:"w-100 text-h6"},[_vm._v(" "+_vm._s(_vm.groupDisplays.length > 0 ? _vm.findFromGroupDisplays(group).text : group)+" ")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.selectIcon))])],1)]}},_vm._l((_vm.priceValues),function(priceValue){return {key:("item." + priceValue),fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toPriceNumber(item[priceValue]))+" ")]}}}),_vm._l((_vm.highlightValues),function(highlightValue){return {key:("item." + highlightValue),fn:function(ref){
var item = ref.item;
return [_c('div',{key:highlightValue,domProps:{"innerHTML":_vm._s(
          _vm.highlighter.highlightOrNot(item[highlightValue], _vm.lastSearchString)
        )}})]}}}),{key:"body.append",fn:function(ref){return [(_vm.lastSearchString !== '')?_c('tr',{staticClass:"hover-none"},[_c('th',{attrs:{"colspan":"100"}},[_c('div',{staticClass:"w-100 mt-4 mb-4 text-center"},[(_vm.loadMoreEnabled)?_c('v-btn',{attrs:{"block":"","depressed":"","large":"","loading":_vm.loadingItems},on:{"click":function($event){return _vm.loadMore()}}},[_vm._v(" Load more results ")]):_vm._e(),(!_vm.loadMoreEnabled)?_c('div',{staticClass:"text-subtitle-1 faded text-center"},[_vm._v(" End of results ")]):_vm._e()],1)])]):_vm._e()]}}],null,true)}):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"d-flex flex-column h-100 overflow-auto"},[_c('v-data-iterator',{class:{ faded: _vm.loadingItems },attrs:{"items":_vm.items,"item-key":_vm.itemKey,"items-per-page":-1,"single-expand":true,"hide-default-footer":"","disable-filtering":true,"disable-pagination":true,"disable-sort":true,"group-by":_vm.groupBy},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var groupedItems = ref.groupedItems;
        var items = ref.items;
return [(!_vm.groupBy)?_c('div',[_c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l((items),function(item,i){return _c('DataTableExpansionPanelMobile',{key:i,attrs:{"item":item,"highlight-string":_vm.lastSearchString,"display-item-values":_vm.displayItemValues,"highlight-values":_vm.highlightValues,"headers":_vm.headers,"select-icon":_vm.selectIcon},on:{"selectItem":_vm.selectItem}})}),1)],1):_vm._e(),(_vm.groupBy)?_c('div',_vm._l((groupedItems),function(groupedItem,i){return _c('div',{key:i},[_c('div',{staticClass:"mt-4 mb-4 text-h4 pl-3 pr-3"},[_vm._v(" "+_vm._s(_vm.groupDisplays.length > 0 ? _vm.findFromGroupDisplays(groupedItem.name).text : groupedItem.name)+" ")]),_c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l((groupedItem.items),function(item,i){return _c('DataTableExpansionPanelMobile',{key:i,attrs:{"item":item,"highlight-string":_vm.lastSearchString,"display-item-values":_vm.displayItemValues,"highlight-values":_vm.highlightValues,"headers":_vm.headers,"select-icon":_vm.selectIcon},on:{"selectItem":_vm.selectItem}})}),1)],1)}),0):_vm._e()]}}],null,false,2094245007)}),(_vm.lastSearchString !== '')?_c('div',{staticClass:"w-100 mt-3 pb-4 text-center"},[(_vm.loadMoreEnabled)?_c('v-btn',{attrs:{"block":"","depressed":"","large":"","loading":_vm.loadingItems},on:{"click":function($event){return _vm.loadMore()}}},[_vm._v(" Load more results ")]):_vm._e(),(!_vm.loadMoreEnabled)?_c('div',{staticClass:"text-subtitle-1 faded text-center"},[_vm._v(" End of results ")]):_vm._e()],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }