import { noomproxyBaseUrl } from "@/api/base/urls";

const shelfBaseUrl = "/stock-shelf";

const shelfUrl = {
  getAll: `${noomproxyBaseUrl}${shelfBaseUrl}`,
  getById: (id) => `${noomproxyBaseUrl}${shelfBaseUrl}/${id}`,
  getByBarcode: () => `${noomproxyBaseUrl}${shelfBaseUrl}/by-barcode`,
};

export { shelfUrl };
