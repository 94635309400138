import store from "@/store/index";
import { shelfApi } from "@/api/shelf";

const shelfService = {
  fetchAllItems: async () => {
    await store.dispatch("setShelfItemsLoading", true);
    try {
      const response = await shelfApi.getAll();
      if (response.ok) {
        await store.dispatch("setShelfItems", await response.json());
      } else {
        await store.dispatch("setShelfError", "ERROR in fetchAllItems");
        console.error("ERROR in fetchAllItems", response);
      }
    } catch (e) {
      await store.dispatch("setShelfError", "ERROR in fetchAllItems: " + e);
      console.error("ERROR in fetchAllItems", e);
    }
    await store.dispatch("setShelfItemsLoading", false);
  },
  fetchById: async (id) => {
    await store.dispatch("setShelfItemsLoading", true);
    try {
      const response = await shelfApi.getById(id);
      if (response.ok) {
        await store.dispatch("selectShelfItem", await response.json());
      } else {
        await store.dispatch("setShelfError", "ERROR in fetchById");
        console.error("ERROR in fetchById", response);
      }
    } catch (e) {
      await store.dispatch("setShelfError", "ERROR in fetchById: " + e);
      console.error("ERROR in fetchById", e);
    }
    await store.dispatch("setShelfItemsLoading", false);
  },
  fetchByBarcode: async (barcode) => {
    await store.dispatch("setShelfItemsLoading", true);
    try {
      const response = await shelfApi.getByBarcode({ barcode });
      if (response.ok) {
        const item = await response.json();
        return item;
      } else {
        await store.dispatch("setShelfError", "ERROR in fetchById");
        console.error("ERROR in fetchById", response);
      }
    } catch (e) {
      await store.dispatch("setShelfError", "ERROR in fetchById: " + e);
      console.error("ERROR in fetchById", e);
    }
    await store.dispatch("setShelfItemsLoading", false);
    return null;
  },
  selectShelf: async (shelf) => {
    await store.dispatch("selectShelfItem", shelf);
  },
  setError: async (error) => {
    await store.dispatch("setShelfError", error);
  },
};

export default shelfService;
