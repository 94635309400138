const generalAllInfoCustomerHeaders = [
  {
    text: "SearchCode",
    align: "start",
    value: "searchCode",
  },
  { text: "", align: "center", value: "foundByCustomerContactName" },
  { text: "Name", value: "name" },
  // { text: "Additional", value: "additional" },
  { text: "City", align: "center", value: "city" },
  { text: "Address", align: "center", value: "address" },
  { text: "", align: "center", value: "actions" },
];
const generalAllInfoCustomerHeadersMobile = generalAllInfoCustomerHeaders;

export { generalAllInfoCustomerHeaders, generalAllInfoCustomerHeadersMobile };
