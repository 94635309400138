<template>
  <div class="pa-2 justify-center w-100 h-100 align-center d-flex">
    <div class="text-h2 text-center">Hello {{ account.name }}!</div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
  computed: {
    account() {
      return this.$store.state.auth.account;
    },
  },
};
</script>

<style scoped></style>
