import { Api } from "@/api/base";
import { shelfUrl } from "@/api/shelf/urls";

const shelfApi = {
  getAll: async () => Api.get(shelfUrl.getAll),
  getById: async (id) => Api.get(shelfUrl.getById(id)),
  getByBarcode: async (body) => Api.post(shelfUrl.getByBarcode(), body),
};

export { shelfApi };
