<template>
  <v-dialog v-model="dialogModel" persistent max-width="500">
    <v-card>
      <v-toolbar dark color="primary lighten-2" flat>
        <v-toolbar-title>Choose shelf</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <ChooseShelf
          :initial-selected-id="initialSelectedId"
          @submit="submit"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ChooseShelf from "@/components/ChooseShelf";
export default {
  name: "ChooseShelfDialog",
  components: { ChooseShelf },
  props: ["dialogModel", "initialSelectedId"],
  methods: {
    submit(newLocation) {
      this.$emit("submit", newLocation);
      this.selectedValue = null;
    },
  },
};
</script>

<style scoped></style>
