export default {
  SET_ITEMS_ITEM(state, payload) {
    state.items = payload;
  },
  SET_ITEMS_LOADING_ITEM(state, payload) {
    state.loadingItems = payload;
  },
  SET_ERROR_ITEM(state, payload) {
    state.error = payload;
  },
  SET_SELECTED_ITEM_ITEM(state, payload) {
    state.selectedItem = payload;
  },
  SET_DELETE_LOADING_ID_ITEM(state, payload) {
    state.loadingDeleteId = payload;
  },
};
