<template>
  <div class="w-100">
    <v-app-bar flat absolute>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.xsOnly"
      ></v-app-bar-nav-icon>
      <v-toolbar-title><v-img contain :src="noominuhkLogo" /></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <v-btn
          :class="
            getPathFirstString(link.link) === firstPathString
              ? 'grey lighten-2'
              : ''
          "
          :disabled="link.disabled"
          text
          depressed
          v-for="link in links"
          :key="link.link"
          @click="$router.push({ path: link.link })"
        >
          <v-icon left dark>{{ link.icon }}</v-icon>
          {{ link.title }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-list nav>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <router-link
            style="text-decoration: none; color: inherit"
            :to="!link.disabled ? link.link : ''"
            v-for="link in links"
            :key="link.link"
          >
            <v-list-item :disabled="link.disabled">
              <v-list-item-title>
                <v-icon>{{ link.icon }}</v-icon>
                {{ link.title }}
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import noominuhkLogo from "@/assets/img/noominuhk_logo.png";
export default {
  name: "Toolbar",
  data() {
    return {
      noominuhkLogo,
      drawer: false,
      group: false,
      links: [
        {
          title: "Products",
          link: "/product",
          icon: "mdi-database",
          disabled: false,
        },
        {
          title: "Customers",
          link: "/customer",
          icon: "mdi-account",
          disabled: false,
        },
        {
          title: "Locations",
          link: "/location",
          icon: "mdi-map-marker",
          disabled: false,
        },
        {
          title: "Inventory",
          link: "/inventory/1",
          // icon: "mdi-view-grid-outline",
          icon: "mdi-clipboard-text",
          disabled: false,
        },
        { title: "Logout", link: "/pass", icon: "mdi-logout", disabled: false },
      ],
    };
  },
  computed: {
    firstPathString() {
      return this.getPathFirstString(this.$route.path);
    },
  },
  methods: {
    getPathFirstString(path) {
      return path.split("/")[1];
    },
  },
};
</script>

<style scoped></style>
