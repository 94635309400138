<template>
  <div
    class="w-100 h-100 justify-center align-center"
    :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-3'"
  >
    asdas
  </div>
</template>

<script>
export default {
  name: "location",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
