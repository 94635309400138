<template>
  <div class="form-login-wrapper">
    <div v-if="!loginLocked">
      <v-row>
        <v-col cols="12" sm="12" class="pa-0">
          <v-text-field
            v-model="inputUsername"
            name="username"
            placeholder="Username"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="pa-0">
          <v-text-field
            v-model="inputPassword"
            type="password"
            name="password"
            placeholder="Password"
            v-on:keyup.enter="login"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="pa-0">
          <v-btn block depressed @click="login" :loading="loginLoading">
            ENTER
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="pa-0">
          <div class="red--text text-wrap text-break" v-if="authError">
            {{ authError }}
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="loginLocked" class="text-h1">LOCKED!</div>
    <div class="version faded">{{ appVersion }}</div>
  </div>
</template>

<script>
import authService from "@/service/authService";
export default {
  name: "LoginContent",
  components: {},
  data() {
    return {
      loginError: false,
      showPassword: false,
      inputUsername: "",
      inputPassword: "",
    };
  },
  computed: {
    loginLoading() {
      return this.$store.state.auth.loginLoading;
    },
    authError() {
      return this.$store.state.auth.error;
    },
    navigatePath() {
      return this.$store.state.auth.navigatePath;
    },
    loginAttempts() {
      return this.$store.state.auth.loginAttempts;
    },
    loginLocked() {
      return this.loginAttempts > 5;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
  mounted() {
    authService.logout();
  },
  methods: {
    async login() {
      await authService.increaseLoginAttempt();
      const loginSuccessful = await authService.login({
        username: this.inputUsername,
        password: this.inputPassword,
        serviceId: process.env.VUE_APP_SERVICE_ID,
      });
      if (loginSuccessful) {
        await authService.resetLoginAttempt();
        await this.$router.push({ path: this.navigatePath || "/" });
      }
    },
  },
};
</script>

<style scoped>
.version {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
}
.form-login-wrapper {
  width: 185px;
}
</style>
