export default {
  setToken({ commit }, payload) {
    commit("SET_TOKEN", payload);
  },
  setLoginLoading({ commit }, payload) {
    commit("SET_LOGIN_LOADING", payload);
  },
  setAuthError({ commit }, payload) {
    commit("SET_AUTH_ERROR", payload);
  },
  setNavigatePath({ commit }, payload) {
    commit("SET_NAVIGATE_PATH", payload);
  },
  setAccount({ commit }, payload) {
    commit("SET_ACCOUNT", payload);
  },
  setAccountRole({ commit }, payload) {
    commit("SET_ACCOUNT_ROLE", payload);
  },
  setAccountPermissions({ commit }, payload) {
    commit("SET_ACCOUNT_PERMISSIONS", payload);
  },
  setAccountError({ commit }, payload) {
    commit("SET_ACCOUNT_ERROR", payload);
  },
  setAccountLoading({ commit }, payload) {
    commit("SET_ACCOUNT_LOADING", payload);
  },
  setAccountItems({ commit }, payload) {
    commit("SET_ACCOUNT_ITEMS", payload);
  },
  increaseLoginAttempt({ commit }) {
    commit("INCREASE_LOGIN_ATTEMPT");
  },
  resetLoginAttempt({ commit }) {
    commit("RESET_LOGIN_ATTEMPT");
  },
};
