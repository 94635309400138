<template>
  <div class="w-100 d-flex flex-column">
    <v-btn
      :disabled="loadingItems"
      x-large
      icon
      :ripple="false"
      @click="getItems"
    >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
    <ScanLocationInput @scanResult="scanResult" />
    <v-select
      class="mt-5"
      v-model="selectedValue"
      item-value="id"
      item-text="name"
      no-data-text="No data"
      :loading="loadingItems"
      :items="items"
      label="=== Choose shelf ==="
      solo
      flat
      background-color="rgba(0,0,0,.06)"
      @change="valueChanged"
    ></v-select>
    <v-btn
      v-if="okButtonVisible"
      :disabled="selectedValue === initialSelectedId || selectedValue === null"
      x-large
      block
      depressed
      color="primary"
      @click="submit"
    >
      OK
    </v-btn>
  </div>
</template>

<script>
import shelfService from "@/service/shelfService";
import ScanLocationInput from "@/components/ScanLocationInput";

export default {
  name: "ChooseShelf",
  components: { ScanLocationInput },
  props: {
    okButtonVisible: {
      type: Boolean,
      default: true,
    },
    initialSelectedId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  watch: {
    /*
    initialSelectedId(newVal) {
      console.log("initial selected change", newVal);
      this.selectedValue = newVal;
      console.log("new selectedValue", this.selectedValue);
    },
    selectedValue(newVal) {
      console.log("selectedValue change", newVal);
    },
     */
  },
  computed: {
    loadingItems() {
      return this.$store.state.shelf.loadingItems;
    },
    items() {
      return this.$store.state.shelf.items;
    },
  },
  mounted() {
    // this.selectedValue = this.initialSelectedId;
  },
  methods: {
    async getItems() {
      await shelfService.fetchAllItems();
    },
    valueChanged(newVal) {
      console.log("valueChanged", newVal);
      this.$emit("changed", newVal);
    },
    submit() {
      this.$emit("submit", this.selectedValue);
      this.selectedValue = null;
    },
    scanResult(item) {
      this.selectedValue = item?.id;
    },
  },
};
</script>

<style scoped></style>
