import state from "@/store/socket/state";
import mutations from "@/store/socket/mutations";
import actions from "@/store/socket/actions";

export default {
  state: {
    ...state,
  },
  mutations: {
    ...mutations,
  },
  actions,
};
