import { Api } from "@/api/base";
import { customerUrl } from "@/api/customer/urls";

const customerApi = {
  search: async (body) => {
    return Api.post(customerUrl.search, body);
  },
  getOne: async (body) => {
    return Api.get(customerUrl.getOne(body));
  },
};

export { customerApi };
