import { Api } from "@/api/base";
import { inventoryChangeUrl } from "@/api/inventoryChange/urls";

const InventoryChangeApi = {
  getActiveInventoryChanges: async () => {
    return Api.get(inventoryChangeUrl.getActiveInventoryChanges);
  },
  getActiveInventoryChangeForProduct: async (productId) => {
    return Api.get(
      inventoryChangeUrl.getActiveInventoryChangeForProduct(productId)
    );
  },
  deleteById: async (id) => {
    return Api.delete(inventoryChangeUrl.deleteById(id));
  },
  modifyItem: async (item) => {
    return Api.post(inventoryChangeUrl.modifyItem, item);
  },
};

export { InventoryChangeApi };
