<template>
  <div class="d-flex flex-column w-100 h-100 pa-2">
    <SearchProductsDialog
      :dialog-model="searchProductsForBarcodeDialog"
      :table-headers="searchProductTableHeaders"
      @selectProduct="searchedProductForBarcodeSelect"
      @close="closeSearchProductDialog"
    />
    <SearchProductsDialog
      :dialog-model="searchProductsForCheckDialog"
      :table-headers="searchProductTableHeaders"
      @selectProduct="searchedProductForCheckSelect"
      @close="closeSearchProductDialog"
    />
    <BarcodeAddConfirmationDialog
      :dialog-model="confirmBarcodeAddDialog"
      :product="selectedSearchedProductForBarcode"
      :barcode="lastScanString"
      @yes="confirmBarcodeAddToProduct"
      @no="cancelBarcodeAddToProduct"
    />
    <QuantityDialog
      :dialog-model="quantityDialog"
      :old-quantity="
        selectedProduct === null ||
        isNaN(selectedProduct) ||
        selectedProduct.totalQuantity === null
          ? -1
          : selectedProduct.totalQuantity
      "
      :loading="loadingInventoryChange"
      @close="quantityDialog = false"
      @submit="modifyInventoryQuantity"
    />
    <ChooseShelfDialog
      :dialog-model="shelfDialog"
      :initial-selected-id="
        selectedProduct !== null && selectedProduct.customShelf !== ''
          ? selectedProduct.customShelf
          : null
      "
      @close="shelfDialog = false"
      @submit="modifyLocation"
    />
    <ScanProductInput
      ref="scanProductInput"
      @scanResults="scanned"
      @searchClick="searchProductsForCheckDialog = true"
    />
    <div
      class="text-h4 align-center d-flex flex-row gap-5"
      v-if="lastScanString && !loadingItems"
    >
      <v-icon x-large>mdi-barcode</v-icon>
      {{ `${lastScanString}` }}
    </div>
    <div class="text-h6 faded" v-if="loadingItems">Loading scan...</div>
    <div
      class="h-100 d-flex flex-column overflow-hidden data-table pt-5"
      :class="{ faded: loadingItems }"
    >
      <v-progress-linear
        v-if="loadingItems || loadingModify"
        indeterminate
        color="primary lighten-2"
      ></v-progress-linear>
      <div
        v-if="selectedProduct && scannedProductsCount === 1"
        class="product-details"
      >
        <div class="text-h2 mb-2 faded" v-if="lastScanString">Found:</div>
        <div class="text-h5 mt-3">{{ selectedProduct.searchCode }}</div>
        <div class="text-h5 mt-3">{{ selectedProduct.code1 }}</div>
        <div class="text-h5 mt-3">{{ selectedProduct.name }}</div>
        <div class="text-h1 mt-7 align-center d-flex">
          <v-btn
            x-large
            width="70px"
            class="mr-3"
            color="primary lighten-1"
            icon
            :ripple="false"
            @click="shelfDialog = true"
          >
            <v-icon x-large class="icon-huge">mdi-map-marker</v-icon>
          </v-btn>
          {{ selectedProduct.customShelf || "-" }}
        </div>
        <div class="text-h1 mt-7 align-center d-flex">
          <v-btn
            x-large
            width="70px"
            class="mr-3"
            color="primary lighten-1"
            icon
            :ripple="false"
            @click="quantityDialog = true"
          >
            <v-icon x-large class="icon-huge">mdi-view-grid</v-icon>
          </v-btn>
          {{
            selectedProduct.totalQuantity === null ||
            isNaN(selectedProduct.totalQuantity)
              ? "-"
              : selectedProduct.totalQuantity
          }}
          <div
            v-if="
              selectedInventoryChange !== null &&
              selectedInventoryChange.id !== undefined
            "
            class="d-flex flex-row"
          >
            <v-icon x-large class="faded icon-huge">mdi-arrow-right</v-icon>
            <div class="d-flex flex-column">
              <div class="d-flex flex-row">
                <div class="text-h2">
                  <v-icon x-large class="faded icon-big">
                    mdi-clipboard-text
                  </v-icon>
                  {{ selectedInventoryChange.inventoryAfter }}
                </div>
              </div>
              <div class="text-subtitle-1">
                {{ selectedInventoryChange.accountName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="scannedProductsCount === 0 && !loadingItems">
        <div class="text-h2 mb-5 faded">Nothing found!</div>
        <v-btn
          x-large
          depressed
          color="blue lighten-1"
          @click="searchProductsForBarcodeDialog = true"
        >
          Add barcode to product
        </v-btn>
      </div>
      <div v-if="scannedProductsCount === null && !loadingItems">
        <div class="text-h2 mb-2 faded">Error!</div>
      </div>
    </div>
  </div>
</template>

<script>
import timeHelper from "@/helper/time";
import { minimalStockProductHeaders } from "@/constants/dataTableHeadersProduct";
import productService from "@/service/productService";
import ScanProductInput from "@/components/ScanProductInput";
import SearchProductsDialog from "@/components/SearchProductsDialog";
import BarcodeAddConfirmationDialog from "@/components/BarcodeAddConfirmationDialog";
import QuantityDialog from "@/components/QuantityDialog";
import inventoryChangeService from "@/service/inventoryChangeService";
import ChooseShelfDialog from "@/components/ChooseShelfDialog";
import shelfService from "@/service/shelfService";

export default {
  name: "CheckProduct",
  components: {
    ChooseShelfDialog,
    QuantityDialog,
    BarcodeAddConfirmationDialog,
    SearchProductsDialog,
    ScanProductInput,
  },
  data() {
    return {
      searchProductTableHeaders: minimalStockProductHeaders,
      scannedProductsCount: -1,
      searchProductsForBarcodeDialog: false,
      searchProductsForCheckDialog: false,
      confirmBarcodeAddDialog: false,
      quantityDialog: false,
      shelfDialog: false,
      selectedSearchedProductForBarcode: null,
    };
  },
  computed: {
    lastScanString() {
      return this.$store.state.product.lastScanString;
    },
    selectedProduct() {
      return this.$store.state.product.selectedItem;
    },
    selectedInventoryChange() {
      return this.$store.state.inventoryChange.selectedItem;
    },
    loadingItems() {
      return this.$store.state.product.loadingItems;
    },
    loadingModify() {
      return this.$store.state.product.loadingModifyId !== null;
    },
    loadingInventoryChange() {
      return this.$store.state.inventoryChange.loadingItems;
    },
  },
  mounted() {
    shelfService.fetchAllItems();
  },
  methods: {
    async scanned(items) {
      if (items === null) {
        // error in scanning
        this.scannedProductsCount = null;
      } else if (items.length === 1) {
        await inventoryChangeService.fetchInventoryForProduct(items[0].id);
        await productService.selectProduct(items[0]);
        this.scannedProductsCount = 1;
      } else if (items.length > 1) {
        await productService.setError(
          "Found more than 1 product with this barcode: " +
            items.map((product) => product.searchCode).join(", ")
        );
        this.scannedProductsCount = items.length;
      } else if (items.length === 0) {
        this.scannedProductsCount = 0;
      }
    },
    searchedProductForBarcodeSelect(product) {
      this.selectedSearchedProductForBarcode = product;
      this.confirmBarcodeAddDialog = true;
    },
    async searchedProductForCheckSelect(product) {
      await productService.clearScanString();
      // await inventoryChangeService.selectInventoryChangeItem(null);
      await inventoryChangeService.fetchInventoryForProduct(product.id);
      await productService.setStoreById(product.id);
      this.scannedProductsCount = 1;
      this.searchProductsForCheckDialog = false;
    },
    async confirmBarcodeAddToProduct() {
      // TODO: add barcode to product
      await this.$refs.scanProductInput.search(this.lastScanString);
      this.selectedSearchedProductForBarcode = null;
      this.confirmBarcodeAddDialog = false;
      this.searchProductsForBarcodeDialog = false;
    },
    cancelBarcodeAddToProduct() {
      this.selectedSearchedProductForBarcode = null;
      this.confirmBarcodeAddDialog = false;
    },
    closeSearchProductDialog() {
      this.searchProductsForBarcodeDialog = false;
      this.searchProductsForCheckDialog = false;
      timeHelper.wait(150).then(() => this.$refs.scanProductInput.focusInput());
    },
    async modifyInventoryQuantity(newQty) {
      await inventoryChangeService.modifyQty(
        {
          product: this.selectedProduct,
          productId: this.selectedProduct.id,
          productSearchCode: this.selectedProduct.searchCode,
          productName: this.selectedProduct.name,
          inventoryBefore: this.selectedProduct.totalQuantity,
        },
        newQty
      );
      await this.$refs.scanProductInput.search(this.lastScanString);
      this.quantityDialog = false;
      await inventoryChangeService.fetchInventoryForProduct(
        this.selectedProduct.id
      );
      await inventoryChangeService.fetchInventoryChangeItems();
    },
    async modifyLocation(newLocation) {
      console.log("modif", this.selectedProduct.id, newLocation);
      if (
        this.selectedProduct !== null &&
        newLocation !== undefined &&
        newLocation !== null
      ) {
        await productService.modifyLocation(
          this.selectedProduct.id,
          newLocation
        );
        // await this.$refs.scanProductInput.search(this.lastScanString);
        this.shelfDialog = false;
        await productService.setStoreById(this.selectedProduct.id);
      }
    },
  },
};
</script>

<style scoped>
.product-details .text-h1 {
  font-size: 8rem !important;
}
.product-details .text-h5 {
  font-size: 2rem !important;
}
.icon-huge {
  font-size: 6rem !important;
}
.icon-big {
  font-size: 4rem !important;
}
</style>
