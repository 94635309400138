<template>
  <div class="pa-3 w-100">
    <v-tabs v-model="tab" grow>
      <v-tab>
        <v-icon class="mr-3">mdi-barcode-scan</v-icon>
        SCAN CHECK
      </v-tab>
      <v-tab>
        <v-icon class="mr-3">mdi-clipboard-text</v-icon>
        STOCK CHANGES
      </v-tab>
      <v-tab-item>
        <div class="mt-3">
          <CheckProduct />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="mt-3"><InventoryChangesContent /></div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import CheckProduct from "@/components/CheckProduct";
import InventoryChangesContent from "@/components/InventoryChangesContent";
export default {
  name: "inventory",
  components: { InventoryChangesContent, CheckProduct },
  data() {
    return {
      tab: 1,
    };
  },
  mounted() {
    // this.tab = this.$route.params.tab;
  },
};
</script>

<style scoped></style>
