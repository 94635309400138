<template>
  <div class="d-flex flex-column w-100 h-100">
    <SearchInput
      :input-model="searchFilter"
      icon="mdi-account"
      :permission-to-send-search="permissionToSendSearch"
      :last-search-string="lastSearchString"
      :loading-items="loadingItems"
      label="Search customers"
      @search="search(0)"
      @update:inputModel="updateInput"
    />
    <div class="h-100 w-100 d-flex flex-column overflow-hidden data-table">
      <DataTable
        :items="tableItems"
        :headers="headers"
        :highlight-values="[
          'searchCode',
          'name',
          'address',
          'foundByCustomerContactName',
        ]"
        :display-item-values="[
          'searchCode',
          'name',
          'foundByCustomerContactName',
        ]"
        :last-search-string="lastSearchString"
        :loading-items="loadingItems"
        :query-limit="itemsLoadLimit"
        :more-items-to-load="moreItemsToLoad"
        :select-icon="selectIcon"
        group-by="byCustomerContact"
        :group-displays="groupDisplays"
        item-key="index"
        @loadMore="loadMore()"
        @selectItem="selectItem"
      />
    </div>
  </div>
</template>

<script>
import customerService from "@/service/customerService";
import SearchInput from "@/components/SearchInput";
import DataTable from "@/components/DataTable";
import productService from "@/service/productService";

export default {
  name: "SearchCustomers",
  components: { DataTable, SearchInput },
  props: ["headers", "selectIcon"],
  data() {
    return {
      itemsLoadOffset: 0,
      itemsLoadLimit: 10,
      minSearchCharCount: 3,
      searchFilter: "",
      moreItemsToLoad: true,
      groupDisplays: [
        {
          value: false,
          text: "Results by company name or address",
        },
        {
          value: true,
          text: "Results by contacts",
        },
      ],
    };
  },
  computed: {
    tableItems() {
      return this.customers.map((item, index) => ({
        index,
        ...item,
      }));
    },
    permissionToSendSearch() {
      return (
        (this.searchFilter.length >= this.minSearchCharCount &&
          this.lastSearchString !== this.searchFilter) ||
        false
      );
    },
    lastSearchString() {
      return this.$store.state.customer.lastSearchString;
    },
    customers() {
      return this.$store.state.customer.items;
    },
    loadingItems() {
      return this.$store.state.customer.loadingItems;
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("selectCustomer", item);
      console.log("emit");
    },
    updateInput(newVal) {
      this.searchFilter = newVal;
    },
    async search(offset) {
      if (this.permissionToSendSearch) {
        this.moreItemsToLoad = true;
        await customerService.search(
          this.searchFilter,
          offset,
          this.itemsLoadLimit
        );
      }
    },
    async loadMore() {
      this.itemsLoadOffset = this.itemsLoadOffset + this.itemsLoadLimit;
      const oldCount = this.customers.length;
      await customerService.search(
        this.searchFilter,
        this.itemsLoadOffset,
        this.itemsLoadLimit
      );
      const newCount = this.customers.length;
      if (oldCount === newCount) {
        this.moreItemsToLoad = false;
      }
    },
  },
};
</script>

<style scoped></style>
