export default {
  setItemError({ commit }, payload) {
    commit("SET_ERROR_ITEM", payload);
  },
  setItemItemsLoading({ commit }, payload) {
    commit("SET_ITEMS_LOADING_ITEM", payload);
  },
  setItemItems({ commit }, payload) {
    commit("SET_ITEMS_ITEM", payload);
  },
  selectItemItem({ commit }, payload) {
    commit("SET_SELECTED_ITEM_ITEM", payload);
  },
  setItemDeleteLoadingId({ commit }, payload) {
    commit("SET_DELETE_LOADING_ID_ITEM", payload);
  },
};
