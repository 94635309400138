import genericState from "@/store/generic/state";

import state from "@/store/inventoryChange/state";
import mutations from "@/store/inventoryChange/mutations";
import actions from "@/store/inventoryChange/actions";

export default {
  state() {
    return {
      ...genericState(),
      ...state,
    };
  },
  mutations: {
    ...mutations,
  },
  actions,
};
