const generalInventoryChangeHeaders = [
  {
    text: "Time",
    align: "start",
    value: "timeCreated",
  },
  {
    text: "Search code",
    align: "start",
    value: "product.searchCode",
  },
  { text: "Name", value: "product.name" },
  { text: "Quantity BEFORE", value: "product.totalQuantity" },
  { text: "Quantity AFTER", value: "inventoryAfter" },
  { text: "Marked by", value: "accountName" },
  { text: "", align: "center", value: "actions" },
];

export { generalInventoryChangeHeaders };
