export default {
  SET_ITEMS_CUSTOMER(state, payload) {
    state.items = payload;
  },
  APPEND_ITEMS_CUSTOMER(state, payload) {
    state.items = [...state.items, ...payload];
  },
  SET_ITEMS_LOADING_CUSTOMER(state, payload) {
    state.loadingItems = payload;
  },
  SET_ERROR_CUSTOMER(state, payload) {
    state.error = payload;
  },
  SET_SELECTED_ITEM_CUSTOMER(state, payload) {
    state.selectedItem = payload;
  },
  SET_DELETE_LOADING_ID_CUSTOMER(state, payload) {
    state.loadingDeleteId = payload;
  },
  SET_LAST_SEARCH_STRING_CUSTOMER(state, payload) {
    state.lastSearchString = payload;
  },
};
