import { tmmasterBaseUrl } from "@/api/base/urls";

const url = {
  checkUserCredentials: `${tmmasterBaseUrl}/authenticate`,
  checkToken: `${tmmasterBaseUrl}/authenticate/checktoken`,
  getTerminalAccountsByOwnerId: (ownerId) =>
    `${tmmasterBaseUrl}/account/terminals/by_owner/${ownerId}`,
};

export { url };
