const generalAllInfoProductHeaders = [
  {
    text: "Search code",
    align: "start",
    sortable: false,
    value: "searchCode",
  },
  {
    text: "Supplier code",
    align: "start",
    sortable: false,
    value: "code1",
  },
  { text: "Name", value: "name" },
  { text: "Standard", value: "nameAddend" },
  { text: "Additional", value: "additional" },
  { text: "Stock qty", align: "center", value: "totalQuantity" },
  { text: "Free qty", align: "center", value: "freeQuantity" },
  { text: "Package qty", align: "center", value: "packageQty" },
  { text: "Price", align: "center", value: "productPrice" },
  { text: "Location", align: "center", value: "customShelf" },
  { text: "", align: "center", value: "actions" },
];
const generalAllInfoProductHeadersMobile = generalAllInfoProductHeaders;

const minimalStockProductHeaders = [
  {
    text: "Search code",
    align: "start",
    sortable: false,
    value: "searchCode",
  },
  {
    text: "Supplier code",
    align: "start",
    sortable: false,
    value: "code1",
  },
  { text: "Name", value: "name" },
  { text: "Stock qty", align: "center", value: "totalQuantity" },
  { text: "Package qty", align: "center", value: "packageQty" },
  { text: "Location", align: "center", value: "customShelf" },
  { text: "", align: "center", value: "actions" },
];

export {
  generalAllInfoProductHeaders,
  minimalStockProductHeaders,
  generalAllInfoProductHeadersMobile,
};
