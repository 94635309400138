import store from "@/store/index";
import { customerApi } from "@/api/customer";

const customerService = {
  search: async (searchString, offset, limit) => {
    await store.dispatch("setCustomerItemsLoading", true);
    try {
      const response = await customerApi.search({
        searchType: "searchCodeAndName",
        searchString: searchString.trim(),
        offset,
        limit,
      });
      if (response.ok) {
        const itemsObj = await response.json();
        const items = [];
        await store.dispatch("setCustomerLastSearchString", searchString);
        itemsObj.byCustomer.forEach((customer) => {
          customer.byCustomerContact = false;
          items.push(customer);
        });
        itemsObj.byCustomerContact.forEach((customer) => {
          customer.byCustomerContact = true;
          items.push(customer);
        });
        if (offset > 0) {
          await store.dispatch("appendCustomerItems", items);
        } else {
          await store.dispatch("setCustomerItems", items);
        }
        // await customerService.updateSelectedItem(items);
      } else {
        const errorMessage =
          "ERROR in customerService search" + (await response.text());
        await store.dispatch("setCustomerError", errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in customerService search" + e;
      await store.dispatch("setCustomerError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setCustomerItemsLoading", false);
  },
  fetchOne: async (id) => {
    await store.dispatch("setCustomerItemsLoading", true);
    try {
      const response = await customerApi.getOne({
        id,
      });
      if (response.ok) {
        const item = await response.json();
        await store.dispatch("selectCustomerItem", item);
        // await customerService.updateSelectedItem(items);
      } else {
        const errorMessage =
          "ERROR in customerService fetchOne" + (await response.text());
        await store.dispatch("setCustomerError", errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in customerService fetchOne" + e;
      await store.dispatch("setCustomerError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setCustomerItemsLoading", false);
  },
};

export default customerService;
