import genericState from "@/store/generic/state";

import state from "@/store/product/state";
import mutations from "@/store/product/mutations";
import actions from "@/store/product/actions";

export default {
  state() {
    return {
      ...genericState(),
      ...state,
    };
  },
  mutations: {
    ...mutations,
  },
  actions,
};
