import store from "@/store/index";
import { productApi } from "@/api/product";

const productService = {
  searchByNameAndBarcode: async (searchString, offset = 0, limit = 30) => {
    await store.dispatch("setProductItemsLoading", true);
    try {
      const responseByBarcode = await productApi.search({
        searchType: "barcode",
        searchString: searchString.trim(),
        offset,
        limit,
      });
      console.log("searchByBarcode response", responseByBarcode);
      if (responseByBarcode.ok) {
        const itemsByBarcode = await responseByBarcode.json();
        let itemsByName = [];
        if (itemsByBarcode.length === 0) {
          const responseByName = await productApi.search({
            searchType: "searchCodeAndName",
            searchString: searchString.trim(),
            offset,
            limit,
          });
          console.log("searchByName response", responseByName);
          if (responseByName.ok) {
            itemsByName = await responseByName.json();
          } else {
            const errorMessage =
              "ERROR in productService search by name" +
              (await responseByName.text());
            await store.dispatch("setProductError", errorMessage);
            console.error(errorMessage);
          }
        }
        const allItems = [...itemsByName, ...itemsByBarcode];
        const items =
          itemsByName.length > 0
            ? [...new Set(allItems.map((item) => item.id))]
            : itemsByBarcode;
        await store.dispatch("setProductLastSearchString", searchString);
        if (offset > 0) {
          await store.dispatch("appendProductItems", items);
        } else {
          await store.dispatch("setProductItems", items);
        }
        // await productService.updateSelectedItem(items);
      } else {
        const errorMessage =
          "ERROR in productService search by barcode " +
          (await responseByBarcode.text());
        await store.dispatch("setProductError", errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in productService search" + e;
      await store.dispatch("setProductError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setProductItemsLoading", false);
  },
  bigSearch: async (searchString, offset = 0, limit = 30) => {
    await store.dispatch("setProductItemsLoading", true);
    try {
      const itemsByCode1 = await productService.getByCode1(
        searchString,
        offset,
        limit
      );
      const itemsByBarcode = await productService.getByBarcode(
        searchString,
        offset,
        limit
      );
      const itemsBySearchCodeAndName =
        await productService.getByNameAndSearchcode(
          searchString,
          offset,
          limit
        );
      const allItems = [
        ...itemsByCode1,
        ...itemsByBarcode,
        ...itemsBySearchCodeAndName,
      ];
      const map = new Map(allItems.map((pos) => [pos.id, pos]));
      const items = [...map.values()];
      await store.dispatch("setProductLastSearchString", searchString);
      if (offset > 0) {
        await store.dispatch("appendProductItems", items);
      } else {
        await store.dispatch("setProductItems", items);
      }
    } catch (e) {
      const errorMessage = "ERROR in productService search" + e;
      await store.dispatch("setProductError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setProductItemsLoading", false);
  },
  getByNameAndSearchcode: async (searchString, offset = 0, limit = 30) => {
    const response = await productApi.search({
      searchType: "searchCodeAndName",
      searchString: searchString.trim(),
      offset,
      limit,
    });
    console.log("searchByName response", response);
    if (response.ok) {
      const items = await response.json();
      // if itemsByName is array of items
      if (Array.isArray(items)) {
        return items;
      } else {
        throw new Error(
          "ERROR in productService search by name. Response is not an array: " +
            items
        );
      }
    } else {
      const errorMessage =
        "ERROR in productService search by name" + (await response.text());
      await store.dispatch("setProductError", errorMessage);
      console.error(errorMessage);
    }
  },
  getByBarcode: async (searchString, offset = 0, limit = 30) => {
    const response = await productApi.search({
      searchType: "barcode",
      searchString: searchString.trim(),
      offset,
      limit,
    });
    console.log("searchByBarcode response", response);
    if (response.ok) {
      const items = await response.json();
      // if itemsByName is array of items
      if (Array.isArray(items)) {
        return items;
      } else {
        throw new Error(
          "ERROR in productService search by barcode. Response is not an array: " +
            items
        );
      }
    } else {
      const errorMessage =
        "ERROR in productService search by barcode" + (await response.text());
      await store.dispatch("setProductError", errorMessage);
      console.error(errorMessage);
    }
  },
  getByCode1: async (searchString, offset = 0, limit = 30) => {
    const response = await productApi.search({
      searchType: "code1",
      searchString: searchString.trim(),
      offset,
      limit,
    });
    console.log("searchByCode1 response", response);
    if (response.ok) {
      const items = await response.json();
      // if itemsByName is array of items
      if (Array.isArray(items)) {
        return items;
      } else {
        throw new Error(
          "ERROR in productService search by code1. Response is not an array: " +
            items
        );
      }
    } else {
      const errorMessage =
        "ERROR in productService search by code1" + (await response.text());
      await store.dispatch("setProductError", errorMessage);
      console.error(errorMessage);
    }
  },
  searchByBarcode: async (searchString, offset = 0, limit = 30) => {
    await store.dispatch("setProductItemsLoading", true);
    try {
      const response = await productApi.search({
        searchType: "barcode",
        searchString: searchString,
        offset,
        limit,
      });
      if (response.ok) {
        const items = await response.json();
        await store.dispatch("setProductLastScanString", searchString);
        // await store.dispatch("selectProductItem", items[0]);
        await store.dispatch("setProductItemsLoading", false);
        return items;
      } else {
        const errorMessage =
          "ERROR in productService searchByBarcode" + (await response.text());
        await store.dispatch("setProductError", errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in productService searchByBarcode" + e;
      await store.dispatch("setProductError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setProductItemsLoading", false);
    return null;
  },
  setStoreById: async (id) => {
    await store.dispatch("setProductItemsLoading", true);
    try {
      const response = await productApi.getById(id);
      if (response.ok) {
        const item = await response.json();
        await productService.selectProduct(item);
      } else {
        const errorMessage =
          "ERROR in productService setById" + (await response.text());
        await store.dispatch("setProductError", errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in productService setById" + e;
      await store.dispatch("setProductError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setProductItemsLoading", false);
  },
  modifyLocation: async (productId, customShelf) => {
    await store.dispatch("setProductModifyLoadingId", productId);
    try {
      const response = await productApi.modifyLocation({
        productId,
        customShelf,
      });
      if (response.ok) {
        await store.dispatch("setProductModifyLoadingId", null);
        return true;
      } else {
        const errorMessage =
          "ERROR in productService modifyLocation " + (await response.text());
        await store.dispatch("setProductError", errorMessage);
        console.error(errorMessage);
      }
    } catch (e) {
      const errorMessage = "ERROR in productService modifyLocation " + e;
      await store.dispatch("setProductError", errorMessage);
      console.error(errorMessage);
    }
    await store.dispatch("setProductModifyLoadingId", null);
    return false;
  },
  clearScanString: async () => {
    await store.dispatch("setProductLastScanString", "");
  },
  selectProduct: async (product) => {
    await store.dispatch("selectProductItem", product);
  },
  setError: async (error) => {
    await store.dispatch("setProductError", error);
  },
};

export default productService;
