<template>
  <div class="d-flex flex-column h-100 overflow-x-hidden">
    <div v-if="loadingItems">
      <v-skeleton-loader
        type="article"
        elevation="0"
        width="450px"
      ></v-skeleton-loader>
    </div>
    <v-container fluid v-if="!loadingItems" class="h-100">
      <v-row class="h-100">
        <v-col cols="12" md="6" sm="12">
          <div class="text-h3 mb-3 text-break">{{ customer.name }}</div>
          <div v-if="customer.searchCode">
            <div class="caption">SearchCode</div>
            <div class="detail-text">{{ customer.searchCode }}</div>
          </div>
          <div v-if="customer.additional">
            <div class="caption">Additional info</div>
            <div class="detail-text">{{ customer.additional }}</div>
          </div>
          <div v-if="customer.customInfo">
            <div class="caption">Custom info</div>
            <div class="detail-text">{{ customer.customInfo }}</div>
          </div>
          <div v-if="customer.regNo">
            <div class="caption">Reg. Nr</div>
            <div class="detail-text">{{ customer.regNo }}</div>
          </div>
          <div v-if="customer.city">
            <div class="caption">City</div>
            <div class="detail-text">{{ customer.city }}</div>
          </div>
          <div v-if="customer.address">
            <div class="caption">Address</div>
            <div class="detail-text">
              <a
                :href="`http://maps.google.com/?q=${customer.address},${customer.city}`"
                target="_blank"
              >
                {{ customer.address }}
              </a>
            </div>
          </div>
          <div v-if="customer.wwwAddr">
            <div class="caption">www</div>
            <div class="detail-text">
              <a :href="customer.wwwAddr" target="_blank">
                {{ customer.wwwAddr }}
              </a>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="12" class="h-100 d-flex flex-column">
          <v-card flat class="justify-center d-flex w-100 bg-light mb-5 pa-2">
            <v-icon large class="">mdi-account-supervisor</v-icon>
          </v-card>
          <div
            v-if="customer.customerContacts.length === 0"
            class="text--secondary"
          >
            No contacts!
          </div>
          <div class="d-flex flex-column overflow-y-auto h-100 contact-list">
            <div v-for="contact in customer.customerContacts" :key="contact.id">
              <div v-if="contact.name">
                <div class="text-h4 mb-3 text-break">{{ contact.name }}</div>
              </div>
              <div v-if="contact.additional">
                <div class="text-subtitle-1 detail-text">
                  <v-icon class="mr-3">mdi-text</v-icon>
                  {{ contact.additional }}
                </div>
              </div>
              <div v-if="contact.email">
                <div class="detail-text">
                  <v-icon class="mr-3">mdi-email-outline</v-icon>
                  <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
                </div>
              </div>
              <div v-if="contact.mobilePhone">
                <div class="detail-text">
                  <v-icon class="mr-3">mdi-phone</v-icon>
                  <a :href="`tel:${contact.mobilePhone}`">
                    {{ contact.mobilePhone }}
                  </a>
                </div>
              </div>
              <div v-if="contact.phone">
                <div class="detail-text">
                  <v-icon class="mr-3">mdi-phone</v-icon>
                  <a :href="`tel:${contact.phone}`">{{ contact.phone }}</a>
                </div>
              </div>
              <div v-if="contact.phone2">
                <div class="detail-text">
                  <v-icon class="mr-3">mdi-phone</v-icon>
                  <a :href="`tel:${contact.phone2}`">{{ contact.phone2 }}</a>
                </div>
              </div>
              <v-divider class="mt-4 mb-4" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import customerService from "@/service/customerService";

export default {
  name: "CustomerDetails",
  props: ["id"],
  computed: {
    customer() {
      return this.$store.state.customer.selectedItem;
    },
    loadingItems() {
      return this.$store.state.customer.loadingItems;
    },
  },
  mounted() {
    this.getCustomerDetails();
  },
  methods: {
    async getCustomerDetails() {
      await customerService.fetchOne(this.id);
    },
  },
};
</script>

<style scoped>
.contact-list {
  min-height: 250px;
}
.detail-text {
  font-size: 1.25rem !important;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
}
.contact-detail-text {
  font-size: 1.75rem !important;
  margin-bottom: 5px;
}
a {
  text-decoration: none;
}
</style>
