export default {
  SET_ITEMS_PRODUCT(state, payload) {
    state.items = payload;
  },
  APPEND_ITEMS_PRODUCT(state, payload) {
    state.items = [...state.items, ...payload];
  },
  SET_ITEMS_LOADING_PRODUCT(state, payload) {
    state.loadingItems = payload;
  },
  SET_ERROR_PRODUCT(state, payload) {
    state.error = payload;
  },
  SET_SELECTED_ITEM_PRODUCT(state, payload) {
    state.selectedItem = payload;
  },
  SET_DELETE_LOADING_ID_PRODUCT(state, payload) {
    state.loadingDeleteId = payload;
  },
  SET_LAST_SEARCH_STRING_PRODUCT(state, payload) {
    state.lastSearchString = payload;
  },
  SET_LAST_SCAN_STRING_PRODUCT(state, payload) {
    state.lastScanString = payload;
  },
  SET_MODIFY_LOADING_ID_PRODUCT(state, payload) {
    state.loadingModifyId = payload;
  },
};
