import { Api } from "@/api/base";
import { url } from "@/api/auth/urls";

const authApi = {
  login: async (body) => Api.post(url.login, body, false), // old
  check: async (body) => Api.post(url.check, body), // old
  checkUserCredentials: async (requestBody) => {
    return Api.post(url.checkUserCredentials, requestBody);
  },
  checkToken: async (requestBody) => {
    return Api.post(url.checkToken, requestBody);
  },
  getTerminalAccountsByOwnerId: async (ownerId) => {
    return Api.get(url.getTerminalAccountsByOwnerId(ownerId));
  },
};

export { authApi };
