export default {
  setCustomerError({ commit }, payload) {
    commit("SET_ERROR_CUSTOMER", payload);
  },
  setCustomerItemsLoading({ commit }, payload) {
    commit("SET_ITEMS_LOADING_CUSTOMER", payload);
  },
  setCustomerItems({ commit }, payload) {
    commit("SET_ITEMS_CUSTOMER", payload);
  },
  appendCustomerItems({ commit }, payload) {
    commit("APPEND_ITEMS_CUSTOMER", payload);
  },
  selectCustomerItem({ commit }, payload) {
    commit("SET_SELECTED_ITEM_CUSTOMER", payload);
  },
  setCustomerDeleteLoadingId({ commit }, payload) {
    commit("SET_DELETE_LOADING_ID_CUSTOMER", payload);
  },
  setCustomerLastSearchString({ commit }, payload) {
    commit("SET_LAST_SEARCH_STRING_CUSTOMER", payload);
  },
};
