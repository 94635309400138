import moment from "moment";

const timeHelper = {
  formatDateTime: (dateTime) => {
    return moment(dateTime).format("DD.MM.YY HH:mm");
  },
  wait: (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  },
};

export default timeHelper;
