import { Api } from "@/api/base";
import { productUrl } from "@/api/product/urls";

const productApi = {
  search: async (body) => {
    return Api.post(productUrl.search, body);
  },
  getById: async (id) => {
    return Api.get(productUrl.getById(id));
  },
  modifyLocation: async (body) => {
    return Api.post(productUrl.modifyLocation, body);
  },
};

export { productApi };
