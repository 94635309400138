<template>
  <v-container fluid class="wrapper">
    <v-row>
      <v-col cols="12" class="btn-wrapper">
        <div class="justify-space-between d-flex flex-row w-100 align-center">
          <v-btn
            icon
            class="btn"
            @click="decrement"
            :disabled="numbersValue < 1"
          >
            -
          </v-btn>
          <div class="text-h3">{{ numbersValue }}</div>
          <v-btn icon class="btn" @click="increment">+</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('1')"
        >
          1
        </v-btn>
      </v-col>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('2')"
        >
          2
        </v-btn>
      </v-col>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('3')"
        >
          3
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('4')"
        >
          4
        </v-btn>
      </v-col>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('5')"
        >
          5
        </v-btn>
      </v-col>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('6')"
        >
          6
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('7')"
        >
          7
        </v-btn>
      </v-col>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('8')"
        >
          8
        </v-btn>
      </v-col>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('9')"
        >
          9
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          color="red lighten-5"
          height="80px"
          @click="deleteOne"
        >
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          height="80px"
          @click="addNumber('0')"
        >
          0
        </v-btn>
      </v-col>
      <v-col cols="4" class="btn-wrapper">
        <v-btn
          depressed
          x-large
          class="btn"
          color="green lighten-5"
          height="80px"
          :loading="loading"
          :disabled="parseInt(initialValue) === numbersValue || loading"
          @click="$emit('submit', numbersValue)"
        >
          <v-icon large>mdi-check</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Keypad",
  props: ["initialValue", "loading"],
  data() {
    return {
      numbersInput: "",
    };
  },
  watch: {
    initialValue(newVal) {
      console.log("initialValue", newVal);
      this.numbersInput = newVal.toString();
    },
  },
  computed: {
    numbersValue() {
      return this.numbersInput ? parseInt(this.numbersInput) : 0;
    },
  },
  mounted() {
    this.numbersInput = this.initialValue;
  },
  methods: {
    addNumber(number) {
      this.numbersInput = this.numbersInput + number;
    },
    deleteOne() {
      this.numbersInput = this.numbersInput.slice(0, -1);
    },
    clearAll() {
      this.numbersInput = "";
    },
    decrement() {
      this.numbersInput = (this.numbersValue - 1).toString();
    },
    increment() {
      this.numbersInput = (this.numbersValue + 1).toString();
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 320px;
}
.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  font-size: 2rem !important;
}
</style>
