<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="align-start">
      <div class="d-flex flex-column">
        <div
          class="faded caption"
          v-html="
            highlighter.highlightOrNot(
              item[displayItemValues[0]],
              highlightString
            )
          "
        />
        <div
          class="text-break text-h6"
          v-html="
            highlighter.highlightOrNot(
              item[displayItemValues[1]],
              highlightString
            )
          "
        />
        <div
          v-if="item[displayItemValues[2]]"
          class="faded caption"
          v-html="
            highlighter.highlightOrNot(
              item[displayItemValues[2]],
              highlightString
            )
          "
        />
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0 grey lighten-3 innerExPan">
      <v-divider></v-divider>
      <v-list dense class="pa-0 grey lighten-3">
        <v-list-item v-for="header in headers" :key="header.value" class="pa-0">
          <v-list-item-content class="text-break">
            <div class="faded caption">{{ header.text }}</div>
            <div class="text-h6">{{ item[header.value] }}</div>
            <v-btn
              class="justify-start"
              icon
              @click="selectItem(item)"
              v-if="header.value === 'actions'"
            >
              <v-icon>{{ selectIcon }}</v-icon>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import highlighter from "@/helper/highlighter";
export default {
  name: "DataTableExpansionPanelMobile",
  props: {
    item: {
      type: Object,
    },
    highlightString: {
      type: String,
      default: "",
    },
    displayItemValues: {
      type: Array,
    },
    highlightValues: {
      type: Array,
      default: function () {
        return [];
      },
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectIcon: {
      type: String,
    },
  },
  data() {
    return {
      highlighter: highlighter,
    };
  },
  methods: {
    selectItem(item) {
      console.log("emit mobile");
      this.$emit("selectItem", item);
    },
  },
};
</script>

<style scoped></style>
