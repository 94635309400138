const highlighter = {
  highlightOrNot(current, searchString) {
    if (
      typeof current != "undefined" &&
      current != null &&
      current &&
      typeof searchString != "undefined" &&
      searchString != null &&
      searchString
    ) {
      let searchKeywordsArray = searchString.split(/\s+/);
      let returnHTMLString = current;
      let foundCount = 0;
      searchKeywordsArray.forEach(function (entry) {
        let reggie = new RegExp(entry, "ig");
        let searchResIndex = current.search(reggie);
        let found = searchResIndex !== -1;
        if (found) {
          foundCount += 1;
          returnHTMLString = returnHTMLString.replace(reggie, function (match) {
            return '<span class="highlight">' + match + "</span>";
          });
        }
      });
      return foundCount === searchKeywordsArray.length
        ? returnHTMLString
        : current;
    } else {
      return current;
    }
  },
};
export default highlighter;
