<template>
  <v-dialog v-model="dialogModel" persistent>
    <v-card v-if="product && barcode">
      <v-card-title class="text-h5 yellow lighten-2">Confirmation</v-card-title>
      <v-card-text class="mt-5 text-h4">
        Are you sure you want to add barcode:
        <div class="font-weight-bold">{{ barcode }}</div>
        to product:
        <div class="font-weight-bold">{{ product.searchCode }}</div>
        <div class="font-weight-bold">{{ product.code1 }}</div>
        <div class="font-weight-bold">{{ product.name }}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-space-between d-flex">
        <v-btn x-large color="red lighten-2" @click="$emit('no')">NO</v-btn>
        <v-btn x-large color="green lighten-2" @click="$emit('yes')">YES</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BarcodeAddConfirmationDialog",
  props: ["dialogModel", "product", "barcode"],
};
</script>

<style scoped></style>
