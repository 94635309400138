<template>
  <div class="d-flex flex-column w-100 h-100">
    <SearchInput
      :input-model="searchFilter"
      icon="mdi-database"
      :permission-to-send-search="permissionToSendSearch"
      :last-search-string="lastSearchString"
      :loading-items="loadingItems"
      label="Search products"
      @search="search(0)"
      @update:inputModel="updateInput"
    />
    <div class="h-100 w-100 d-flex flex-column overflow-hidden data-table">
      <DataTable
        :items="products"
        :headers="headers"
        :price-values="['productPrice']"
        :highlight-values="['searchCode', 'name', 'code1']"
        :display-item-values="['searchCode', 'name', 'code1']"
        :last-search-string="lastSearchString"
        :loading-items="loadingItems"
        :more-items-to-load="moreItemsToLoad"
        :select-icon="selectIcon"
        :query-limit="itemsLoadLimit"
        @loadMore="loadMore()"
        @selectItem="selectItem"
      />
    </div>
  </div>
</template>

<script>
import productService from "@/service/productService";
import DataTable from "@/components/DataTable";
import SearchInput from "@/components/SearchInput";

export default {
  name: "SearchProducts",
  components: { SearchInput, DataTable },
  props: ["selectIcon", "headers"],
  data() {
    return {
      itemsLoadOffset: 0,
      itemsLoadLimit: 10,
      minSearchCharCount: 3,
      searchFilter: "",
      moreItemsToLoad: true,
    };
  },
  computed: {
    permissionToSendSearch() {
      return (
        (this.searchFilter.length >= this.minSearchCharCount &&
          this.lastSearchString !== this.searchFilter) ||
        false
      );
    },
    lastSearchString() {
      return this.$store.state.product.lastSearchString;
    },
    products() {
      return this.$store.state.product.items;
    },
    loadingItems() {
      return this.$store.state.product.loadingItems;
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("selectProduct", item);
    },
    updateInput(newVal) {
      this.searchFilter = newVal;
    },
    async search(offset) {
      if (this.permissionToSendSearch) {
        this.moreItemsToLoad = true;
        await productService.bigSearch(
          this.searchFilter,
          offset,
          this.itemsLoadLimit
        );
      }
    },
    async loadMore() {
      this.itemsLoadOffset = this.itemsLoadOffset + this.itemsLoadLimit;
      const oldProductCount = this.products.length;
      await productService.bigSearch(
        this.searchFilter,
        this.itemsLoadOffset,
        this.itemsLoadLimit
      );
      const newProductCount = this.products.length;
      if (oldProductCount === newProductCount) {
        this.moreItemsToLoad = false;
      }
    },
  },
};
</script>

<style scoped>
.innerExPan > * {
  padding: 0 0 0 50px !important;
}
.data-table /deep/ tbody /deep/ tr:hover {
  background: white !important;
}
</style>
