export default {
  setInventoryChangeError({ commit }, payload) {
    commit("SET_ERROR", payload);
  },
  setInventoryChangeItemsLoading({ commit }, payload) {
    commit("SET_ITEMS_LOADING", payload);
  },
  setInventoryChangeItems({ commit }, payload) {
    commit("SET_ITEMS", payload);
  },
  selectInventoryChangeItem({ commit }, payload) {
    commit("SET_SELECTED_ITEM", payload);
  },
  modifyQtyLoading({ commit }, payload) {
    commit("SET_MODIFY_QTY_LOADING", payload);
  },
  setInventoryChangeDeleteLoadingId({ commit }, payload) {
    commit("SET_DELETE_LOADING_ID", payload);
  },
};
