<template>
  <v-container fluid class="h-100">
    <div
      class="
        pa-lg-8 pa-sm-2
        login-wrapper
        justify-center
        align-center
        h-100
        w-100
        d-flex
        flex-column
        gap-20
      "
    >
      <img :src="noominuhkLogo" width="230px" />
      <LoginContent />
    </div>
  </v-container>
</template>

<script>
import LoginContent from "@/components/LoginContent";
import noominuhkLogo from "@/assets/img/noominuhk_logo.png";
export default {
  name: "login",
  components: { LoginContent },
  data: function () {
    return {
      noominuhkLogo,
    };
  },
};
</script>

<style scoped></style>
