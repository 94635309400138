import { noomproxyBaseUrl } from "@/api/base/urls";

const productBaseUrl = "/product";

const productUrl = {
  search: `${noomproxyBaseUrl}${productBaseUrl}/search`,
  modifyLocation: `${noomproxyBaseUrl}${productBaseUrl}/location`,
  getById: (id) => `${noomproxyBaseUrl}${productBaseUrl}/${id}`,
};

export { productUrl };
