<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="12">
        <div class="text-h2 text-center">Select user</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12" class="justify-center d-flex">
        <UserGrid />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserGrid from "@/components/UserGrid";
export default {
  name: "SelectUser",
  components: { UserGrid },
};
</script>

<style scoped></style>
