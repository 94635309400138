import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
// import VueSocketIO from "vue-socket.io";
// import { backendBaseUrl } from "@/api/base/urls";

Vue.config.productionTip = false;

/*
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: backendBaseUrl,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
    // options: { path: "/my-app/" }, //Optional options
  })
);
 */

window.vueRouterHook = async (path) => {
  await router.push(path);
};

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
