import { noomproxyBaseUrl } from "@/api/base/urls";

const productBarcodeBaseUrl = "/barcode";

const productBarcodeUrl = {
  getByProductId: (id) =>
    `${noomproxyBaseUrl}${productBarcodeBaseUrl}/by-product/${id}`,
};

export { productBarcodeUrl };
