import store from "@/store";
import { InventoryChangeApi } from "@/api/inventoryChange";
import { Helper } from "@/util/helper";

const inventoryChangeService = {
  sanitizeForApiSending: (inventoryChangeItem) => {
    inventoryChangeItem =
      Helper.removeNullValuesFromObject(inventoryChangeItem);
    delete inventoryChangeItem.product;
    delete inventoryChangeItem.timeCreated;
    return inventoryChangeItem;
  },
  updateAccountFields: (inventoryChangeItem) => {
    inventoryChangeItem.accountId = store.state.auth.account.id;
    inventoryChangeItem.accountName = store.state.auth.account.name;
    return inventoryChangeItem;
  },
  fetchInventoryChangeItems: async () => {
    await store.dispatch("setInventoryChangeItemsLoading", true);
    try {
      const response = await InventoryChangeApi.getActiveInventoryChanges();
      if (response.ok) {
        await store.dispatch("setInventoryChangeItems", await response.json());
      } else {
        await store.dispatch(
          "setInventoryChangeError",
          "ERROR in fetchInventoryChangeItems"
        );
        console.error("ERROR in fetchInventoryChangeItems", response);
      }
    } catch (e) {
      await store.dispatch(
        "setInventoryChangeError",
        "ERROR in fetchInventoryChangeItems: " + e
      );
      console.error("ERROR in fetchInventoryChangeItems", e);
    }
    await store.dispatch("setInventoryChangeItemsLoading", false);
  },
  fetchInventoryForProduct: async (productId) => {
    await store.dispatch("setInventoryChangeItemsLoading", true);
    try {
      const response =
        await InventoryChangeApi.getActiveInventoryChangeForProduct(productId);
      if (response.ok) {
        await store.dispatch(
          "selectInventoryChangeItem",
          await response.json()
        );
      } else {
        await store.dispatch(
          "setInventoryChangeError",
          "ERROR in fetchInventoryForProduct"
        );
        console.error("ERROR in fetchInventoryForProduct", response);
      }
    } catch (e) {
      await store.dispatch(
        "setInventoryChangeError",
        "ERROR in fetchInventoryChangeItems: " + e
      );
      console.error("ERROR in fetchInventoryChangeItems", e);
    }
    await store.dispatch("setInventoryChangeItemsLoading", false);
  },
  deleteById: async (id) => {
    await store.dispatch("setInventoryChangeDeleteLoadingId", id);
    try {
      const response = await InventoryChangeApi.deleteById(id);
      if (!response.ok) {
        await store.dispatch(
          "setInventoryChangeError",
          "ERROR in fetchInventoryChangeItems"
        );
        console.error("ERROR in inventoryChangeService deleteById", response);
      }
    } catch (e) {
      await store.dispatch(
        "setInventoryChangeError",
        "ERROR in fetchInventoryChangeItems: " + e
      );
      console.error("ERROR in inventoryChangeService deleteById", e);
    }
    await store.dispatch("setInventoryChangeDeleteLoadingId", null);
  },
  selectInventoryChangeItem: async (inventoryChangeItem) => {
    await store.dispatch("selectInventoryChangeItem", inventoryChangeItem);
  },
  modifyQty: async (item, newQty) => {
    await store.dispatch("modifyQtyLoading", true);
    item.inventoryAfter = newQty;
    item = inventoryChangeService.updateAccountFields(item);
    item = inventoryChangeService.sanitizeForApiSending(item);
    try {
      const response = await InventoryChangeApi.modifyItem(item);
      if (!response.ok) {
        await store.dispatch("setInventoryChangeError", "ERROR in modifyQty");
        console.error("ERROR in modifyQty", response);
      }
    } catch (e) {
      await store.dispatch(
        "setInventoryChangeError",
        "ERROR in modifyQty: " + e
      );
      console.error("ERROR in modifyQty", e);
    }
    await store.dispatch("modifyQtyLoading", false);
  },
};

export default inventoryChangeService;
