<template>
  <div
    class="w-100 h-100 justify-center align-center d-flex"
    :class="$vuetify.breakpoint.xsOnly ? '' : 'pa-3'"
  >
    <div class="choose-shelf-wrapper">
      <ChooseShelf @submit="selectLocation" />
    </div>
  </div>
</template>

<script>
import ChooseShelf from "@/components/ChooseShelf";
export default {
  name: "chooseLocation",
  components: { ChooseShelf },
  data() {
    return {};
  },
  methods: {
    selectLocation(locationId) {
      this.$router.push({ path: `/location/${locationId}` });
    },
  },
};
</script>

<style scoped>
.choose-shelf-wrapper {
  max-width: 500px;
}
</style>
