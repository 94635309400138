<template>
  <v-text-field
    hide-details
    autofocus
    full-width
    class="scan-input"
    v-model="searchFilter"
    label="Scan barcode"
    ref="scanInput"
    prepend-inner-icon="mdi-barcode-scan"
    clearable
    @click:clear="clearInput"
    v-on:keydown.enter="search(searchFilter)"
    solo
    flat
    :background-color="
      inputFocus && !loadingItems ? 'green lighten-3' : 'red lighten-3'
    "
    :loading="loadingItems"
    :disabled="loadingItems"
    @focus="inputFocus = true"
    @blur="inputFocus = false"
  />
</template>

<script>
import shelfService from "@/service/shelfService";

export default {
  name: "ScanLocationInput",
  data() {
    return {
      inputFocus: false,
      searchFilter: "",
      minSearchCharCount: 3,
    };
  },
  computed: {
    loadingItems() {
      return this.$store.state.shelf.loadingItems;
    },
  },
  methods: {
    async search(searchFilter) {
      const item = await shelfService.fetchByBarcode(searchFilter);
      this.$emit("scanResult", item);
      this.clearInput();
    },
    clearInput() {
      this.searchFilter = "";
      this.focusInput();
    },
    focusInput() {
      this.$refs.scanInput.$refs.input.focus();
    },
  },
};
</script>

<style scoped>
.scan-input >>> input {
  font-size: x-large;
}
.scan-input >>> label {
  font-size: x-large;
}
.scan-input >>> .mdi-keyboard {
  font-size: 2.5rem;
}
.scan-input >>> .mdi-barcode-scan {
  font-size: 1.75rem;
}
.scan-input >>> button {
  font-size: xx-large;
}
</style>
