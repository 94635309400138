var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column w-100 h-100"},[_c('v-btn',{attrs:{"x-large":""},on:{"click":_vm.getInventoryChanges}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Refresh ")],1),_c('v-data-table',{staticClass:"h-100 d-flex flex-column overflow-hidden data-table",class:{ faded: _vm.loadingItems },attrs:{"item-class":"table-row","fixed-header":"","dense":_vm.$vuetify.breakpoint.xsOnly,"height":"100%","headers":_vm.headers,"items":_vm.inventoryChanges,"items-per-page":_vm.itemsPerPage,"loading":_vm.loadingItems,"disable-sort":_vm.$vuetify.breakpoint.xsOnly,"hide-default-footer":_vm.inventoryChanges.length === 0,"footer-props":{
      itemsPerPageOptions: [_vm.itemsPerPage],
      options: {
        itemsPerPage: _vm.itemsPerPage,
        disableItemsPerPage: true,
        itemsPerPageText: '',
      },
    }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red lighten-2","icon":""},on:{"click":function($event){return _vm.deleteInventoryChange(item.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}},{key:"item.timeCreated",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.timeCreated))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }